import { skipToken, useMutation } from '@tanstack/react-query';
import {
  Organisation,
  WorkflowStep,
  batchUploadCampaignAttachments,
} from 'shared/db';
import { supabase } from '../utils';
import { notifications } from '@mantine/notifications';
import { trpc } from '../setup';

export const useWorkflowSteps = (workflow_id: number) =>
  trpc.organization.workflow.getWorkflowSteps.useQuery(
    workflow_id ? { workflow_id } : skipToken,
  );

export const useCreateWorkflowStep = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.workflow_steps.create.useMutation({
    onSuccess: (data) => {
      utils.organization.workflow.getWorkflowSteps.setData(
        {
          workflow_id: data.workflow_id,
        },
        (previous) => (previous ? [...previous, data] : [data]),
      );
    },
  });
};

export const useUpdateWorkflowStep = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.workflow_steps.update.useMutation({
    onSuccess: (data) => {
      utils.organization.workflow.getWorkflowSteps.setData(
        {
          workflow_id: data.workflow_id,
        },
        (previous) => previous?.map((c) => (c.id === data.id ? data : c)),
      );
    },
  });
};

export const useDeleteWorkflowStep = () => {
  const utils = trpc.useUtils();
  return trpc.organization.workflow.workflow_steps.delete.useMutation({
    onSuccess: (data) => {
      utils.organization.workflow.getWorkflowSteps.setData(
        {
          workflow_id: data.workflow_id,
        },
        (previous) => previous?.filter((c) => c.id !== data.id),
      );
    },
  });
};

export const useUploadWorkflowStepAttachment = (
  organisation: Organisation,
  workflowStep: WorkflowStep,
) => {
  const { mutateAsync: updateWorkflowStep } = useUpdateWorkflowStep();
  return useMutation({
    mutationFn: async (files: File[]) => {
      const uploadedFilePaths = await batchUploadCampaignAttachments(
        supabase,
        files,
        organisation.id,
      );
      return updateWorkflowStep({
        ...workflowStep,
        attachments: uploadedFilePaths,
        workflow_step_id: workflowStep.id,
        subject: workflowStep.subject || undefined,
      });
    },
    onError() {
      notifications.show({
        title: 'Error',
        message: 'Duplicated file name. Please rename your attachment.',
        color: 'red',
      });
    },
    onSuccess() {
      notifications.show({
        title: 'Attachment uploaded',
        message: 'Attachment saved. You can close the modal now.',
      });
    },
  });
};

export const useDeleteWorkflowStepAttachment = (workflowStep: WorkflowStep) => {
  const { mutateAsync: updateWorkflowStep } = useUpdateWorkflowStep();

  return useMutation({
    mutationFn: async (file: string) => {
      return updateWorkflowStep({
        ...workflowStep,
        workflow_step_id: workflowStep.id,
        subject: workflowStep.subject || undefined,
        attachments: workflowStep.attachments.filter((path) => path !== file),
      });
    },
    onSuccess() {
      notifications.show({
        title: 'Attachment deleted.',
        message: 'You will not see it once you close this modal.',
      });
    },
  });
};
