import { ActionIcon, Box, Flex, Loader, Popover } from '@mantine/core';
import { useCalculateCompScore, useOrganisationStore } from 'fe/queries';
import { formatListedDate, formatUIPrice } from 'shared/formatter';
import { IconLink } from '@tabler/icons-react';
import { Property, PropertyCompView } from 'shared/db';
import classes from './PropertyInfo.module.scss';
import { ComparableAction } from './ComparableAction';
import { useDisclosure } from '@mantine/hooks';
import { Score, Typography } from 'fe/ui/shared';

interface PropertyInfoProps {
  property: Property;
  comparable: PropertyCompView;
}

export function ComparableRow({ property, comparable }: PropertyInfoProps) {
  const { organisation } = useOrganisationStore();
  const { data: compScore, isLoading: isLoadingCompScore } =
    useCalculateCompScore({
      organisationId: organisation?.id,
      subjectProperty: property,
      comparableProperty: comparable,
    });
  const [opened, { close, open }] = useDisclosure(false);
  const score = compScore?.score || 0;
  return (
    <tr className={classes.tableRow} key={comparable.street_address}>
      <td>
        <ActionIcon
          component="a"
          target="__blank"
          href={`https://www.zillow.com/homedetails/11213/${comparable.zpid}_zpid/?`}
          variant="outline"
        >
          <IconLink size="1rem" />
        </ActionIcon>
      </td>
      <td>{comparable.street_address}</td>
      <td>{comparable.price ? formatUIPrice(comparable.price) : '–'}</td>
      <td>{formatListedDate(comparable.listed_date)}</td>
      <td>{comparable.living_area_sqft}</td>
      <td>{comparable.year_built}</td>
      <td>{comparable.property_comps[0].proximity?.toFixed(2)} miles</td>
      <td>
        {isLoadingCompScore ? (
          <Loader size="xs" />
        ) : compScore ? (
          <Popover
            width={250}
            position="bottom"
            withArrow
            shadow="md"
            opened={opened}
            withinPortal
          >
            <Popover.Target>
              <Box onMouseEnter={open} onMouseLeave={close}>
                <Score value={score} maxValue={100} />
              </Box>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
              <Flex direction="column" gap="xs">
                {compScore?.sqftScore ? (
                  <Flex justify="space-between">
                    <Typography fz="xs"> SQFT score: </Typography>
                    <Score
                      value={compScore?.sqftScore}
                      maxValue={compScore.sqftWeight}
                    />
                  </Flex>
                ) : null}

                {compScore?.proximityScore ? (
                  <Flex justify="space-between">
                    <Typography fz="xs">Proximity score: </Typography>
                    <Score
                      value={compScore?.proximityScore}
                      maxValue={compScore.proximityWeight}
                    />
                  </Flex>
                ) : null}

                {compScore?.yearScore ? (
                  <Flex justify="space-between">
                    <Typography fz="xs">Year built score: </Typography>
                    <Score
                      value={compScore?.yearScore}
                      maxValue={compScore.yearWeight}
                    />
                  </Flex>
                ) : null}

                {compScore?.lotSizeScore ? (
                  <Flex justify="space-between">
                    <Typography fz="xs">Lot size score: </Typography>
                    <Score
                      value={compScore?.lotSizeScore}
                      maxValue={compScore.lotSizeWeight}
                    />
                  </Flex>
                ) : null}

                {compScore?.soldDaysScore ? (
                  <Flex justify="space-between">
                    <Typography fz="xs">Lot size score: </Typography>
                    <Score
                      value={compScore.soldDaysScore}
                      maxValue={compScore.soldDaysWeight}
                    />
                  </Flex>
                ) : null}
              </Flex>
            </Popover.Dropdown>
          </Popover>
        ) : (
          'N/A'
        )}
      </td>
      <td>
        <ComparableAction comparable={comparable.property_comps[0]} />
      </td>
    </tr>
  );
}
