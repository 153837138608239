import { IconFile, IconPdf, IconPhoto } from '@tabler/icons-react';

interface FileIconProps {
  mimeType: string;
}
export function FileIcon({ mimeType }: FileIconProps) {
  if (mimeType.includes('image')) {
    return <IconPhoto />;
  } else if (mimeType.includes('pdf')) {
    return <IconPdf />;
  } else {
    return <IconFile />;
  }
}
