import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi, supabase } from '../utils';
import { format, startOfToday, startOfTomorrow } from 'date-fns';

interface DownloadAttachmentsParams {
  street_address: string;
  zip_code: number;
  campaign_id: number;
}

export async function downloadAttachments({
  street_address,
  zip_code,
  campaign_id,
}: DownloadAttachmentsParams) {
  const session = await supabase.auth.getSession();
  if (!session || !session.data.session) {
    throw new Error('Not logged in downloadAttachments');
  }
  const baseURL = import.meta.env['VITE_API_URL'];
  let downloadAttachmentsURL = `${baseURL}/httpDownloadOfferContract?authorization=${session.data.session.access_token}`;
  downloadAttachmentsURL += `&campaignId=${campaign_id}&streetAddress=${street_address}&zipCode=${zip_code}&noUpdate=true`;
  window.open(downloadAttachmentsURL, '_blank');
}

interface FetchOfferEmailContentParams {
  street_address: string;
  campaignId: number;
  zip_code: number;
}

interface OfferEmailContent {
  content: string;
  subject: string;
}

export async function fetchOfferEmailContent({
  street_address,
  zip_code,
  campaignId,
}: FetchOfferEmailContentParams) {
  return await getApi<OfferEmailContent>(
    `/httpFetchEmailContent?streetAddress=${street_address}&zipCode=${zip_code}&campaignId=${campaignId}`,
  );
}

export const useFetchOfferEmailContent = () =>
  useMutation({
    mutationFn: fetchOfferEmailContent,
  });

export interface CampaignOffer {
  id: string;
  campaign_id: number;
  property_zpid: string;
  approved: boolean;
  property_address: string;
  property_price: string;
}

export async function fetchCampaignOffers(
  organisationId: number,
): Promise<CampaignOffer[]> {
  const formatSupabase = (date: Date) => format(date, 'yyyy-MM-dd');
  const { data, error } = await supabase
    .from('leads')
    .select('*, campaigns!inner(*)')
    .eq('campaigns.organisation_id', organisationId)
    .eq('approved', true)
    .gte('created_at', formatSupabase(startOfToday()))
    .lt('created_at', formatSupabase(startOfTomorrow()));

  if (error) {
    throw new Error("Couldn't fetch campaign offers");
  }

  return data;
}

export const useCampaignOffers = (organisationId: number) =>
  useQuery({
    queryKey: ['campaignOffers', organisationId],
    queryFn: () => fetchCampaignOffers(organisationId),
  });
