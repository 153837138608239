import { Text as MantineText } from '@mantine/core';

export function Typography(props: any) {
  if (props.variant === 'h3') {
    return <MantineText fz={22} fw="bold" {...props} />;
  }
  if (props.variant === 'label') {
    return <MantineText fw="bold" size="sm" tt="uppercase" {...props} />;
  }
  return <MantineText {...props} />;
}
