import { Container, Flex } from '@mantine/core';
import { Layout } from 'fe/feature/layout';
import { Title, Text } from '@mantine/core';

export function ServiceAgreement() {
  return (
    <Layout noMenu>
      <Container size="sm" style={{ textAlign: 'center' }}>
        <Flex direction="column" gap="md">
          <Title order={1}>Marketing Software Contract Agreement</Title>

          <Text>Agreement Start Date: April 1st, 2024</Text>

          <Text>
            This Marketing Software Contract Agreement ("Agreement") is entered
            into between [Your Company Name], located at [Your Company Address],
            hereinafter referred to as the "Provider," and Hey Rafi LLC, located
            in Austin Texas, hereinafter referred to as the "User."
          </Text>

          <Title order={2}>1. Services Provided:</Title>
          <Text>
            Provider agrees to grant User access to its marketing software
            platform ("Platform") for the purpose of facilitating marketing
            activities within the following counties and states:
          </Text>
          <Text>[List of licensed counties, states]</Text>
          <Text>
            Furthermore, the parties acknowledge that this agreement constitutes
            an exclusivity agreement. By accepting access to the Platform, User
            acknowledges that Provider will not license the Platform to any
            other parties within the specified counties unless otherwise agreed
            upon in writing by both parties.
          </Text>
          <Text>
            User understands and agrees that they hold exclusive rights to
            utilize the Platform within the licensed counties, and Provider will
            not sublicense or grant access to any other parties within these
            territories until User releases their rights or until Provider
            terminates User's access to the Platform as outlined in this
            Agreement, unless otherwise agreed upon in writing by both parties.
          </Text>

          <Title order={2}>2. User Consent to Charges:</Title>
          <Text>
            By using the Platform, User agrees to pay all monthly charges as
            well as an onboarding expense, as specified below, incurred
            according to the pricing plan selected by the User. User acknowledge
            and agree that they will be responsible for any fees or charges
            associated with the use of the Platform.
          </Text>
          <Text>
            Additionally, Provider reserves the sole discretion to change
            charges as necessary and will make its best efforts to notify User
            of such changes prior to their implementation. Any changes in
            charges will be communicated to User via email or through the
            Platform's notification system.
          </Text>
          <Text>
            Onboarding Expense:
            <br />
            [List onboarding expense details here]
          </Text>
          <Text>
            Note: Charges and onboarding expenses are subject to change at
            Provider's discretion.
          </Text>

          <Title order={2}>3. Terms of Service:</Title>
          <Text>
            User agrees to abide by all terms and conditions outlined in the
            Provider's Terms of Service, which govern the use of the Platform.
            The Provider reserves the right to update or modify the Terms of
            Service at any time without prior notice.
          </Text>

          <Title order={2}>4. Privacy Policy:</Title>
          <Text>
            Provider acknowledges that the privacy of User's data is of utmost
            importance. Provider agrees not to share any of User's business
            information with any third parties without User's explicit consent,
            except where required by law.
          </Text>
          <Text>
            Provider may use anonymized and aggregated data, including marketing
            strategies and best practices derived from User's use of the
            Platform, to improve the Platform and share insights with other
            customers. However, Provider will not disclose any identifiable
            information about User's business without User's permission.
          </Text>

          <Title order={2}>5. Termination:</Title>
          <Text>
            This Agreement shall remain in effect until terminated by either
            party. User may terminate this Agreement by discontinuing the use of
            the Platform and providing written notice to the Provider. Provider
            reserves the right to terminate this Agreement and suspend User's
            access to the Platform for any violation of the Terms of Service or
            non-payment of charges.
          </Text>

          <Title order={2}>6. Governing Law:</Title>
          <Text>
            This Agreement shall be governed by and construed in accordance with
            the laws of Texas. Any disputes arising out of or in connection with
            this Agreement shall be resolved through amicable negotiation. If a
            resolution cannot be reached, the parties agree to submit to the
            exclusive jurisdiction of the courts of their choosing.
          </Text>
        </Flex>
      </Container>
    </Layout>
  );
}
