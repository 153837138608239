import { Box, Button, Flex } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { Organisation } from 'shared/db';
import {
  Products,
  useOrganisationLocations,
  useUpdateOrganisationLocations,
} from 'fe/queries';
import { LocationsSelector } from 'fe/feature/layout';
import { InputWrapper } from 'fe/ui/shared';

interface OrganisationBasicFormProps {
  organisation?: Organisation;
}

export function LocationsForm({ organisation }: OrganisationBasicFormProps) {
  const { data: organisationLocations } = useOrganisationLocations(
    organisation?.id || 0,
  );
  const {
    mutateAsync: updateOrganisationLocations,
    isPending: isLoadingLocationsUpdate,
  } = useUpdateOrganisationLocations(organisation?.id || 0);

  const form = useForm({
    initialValues: {
      locations:
        organisationLocations?.map((location) =>
          location.zip_code.toString(),
        ) || [],
    },
  });

  useEffect(() => {
    if (!organisation) return;
    form.setValues({
      locations:
        organisationLocations?.map((location) =>
          location.zip_code.toString(),
        ) || [],
    });
  }, [organisation, organisationLocations]);

  const submitForm = form.onSubmit((values) => {
    updateOrganisationLocations({
      locations: values.locations.map(Number),
      currentLocations:
        organisationLocations?.map((location) => location.zip_code) || [],
    });
  });

  return (
    <Box mx="auto">
      <Flex>
        <form onSubmit={submitForm} style={{ width: '100%' }}>
          <InputWrapper
            title="Locations available"
            description="What locations are available for this organisation?"
          >
            <LocationsSelector
              inputProps={form.getInputProps('locations')}
              organisationId={organisation?.id || 0}
              type={Products.LISTINGS}
            />
          </InputWrapper>

          <Button type="submit" loading={isLoadingLocationsUpdate}>
            Save changes
          </Button>
        </form>
      </Flex>
    </Box>
  );
}
