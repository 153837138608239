import { Flex, Title } from '@mantine/core';
import { ResponseInsightsCards } from './ResponseInsightsCards';
// import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Tooltip, Pie, PieChart, Legend } from 'recharts';
import {
  useInsightsStore,
  useOrganisationStore,
  useResponseInsights,
} from 'fe/queries';
import { skipToken } from '@tanstack/react-query';

export function ResponseInsights() {
  const { organisation } = useOrganisationStore();
  if (!organisation) {
    return null;
  }
  const params = useInsightsStore((state) => ({
    campaignId: state.campaignId,
    dateRange: state.dateRange,
    granularity: state.granularity,
  }));
  const startDate = params.dateRange[0];
  const endDate = params.dateRange[1];

  const { data, isLoading } = useResponseInsights(
    startDate && endDate
      ? {
          organization_id: organisation.id,
          campaign_id: params.campaignId || undefined,
          date_start: startDate.valueOf(),
          date_end: endDate.valueOf(),
          granularity: params.granularity,
        }
      : skipToken,
  );
  return (
    <Flex gap="lg" direction="column">
      <Title order={2}>Response insights</Title>

      <ResponseInsightsCards data={data || []} loading={isLoading} />
    </Flex>
  );
}
