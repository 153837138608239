import {
  skipToken,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  CampaignPropertyNote,
  InboxItem,
  Organisation,
  Products,
  PropertyMessage,
} from 'shared/db';
import { callApi, postApi } from '../utils';
import { notifications } from '@mantine/notifications';
import { getLabelFromUrl } from 'shared/formatter';
import {
  analyzeLeadInboxEndpoint,
  organizationFetchInboxEndpoint,
  postLeadAiFeedbackEndpoint,
} from 'shared/api';
import {
  LeadAiSuggestions,
  LeadStatusChanges,
  PropertyMessageWithAttachments,
} from 'be/db';
import { Selectable } from 'kysely';
import { removeSuggestionsInboxItem } from './utils';
import { trpc } from '../setup';
import { type inferProcedureOutput } from '@trpc/server';
import { AppRouter } from 'shared/types';

export const useInboxItems = (
  organisation_id: number | undefined,
  search_term: string | undefined = undefined,
  lead_status: string[] | undefined = undefined,
  campaign_ids: number[] | undefined = undefined,
  campaign_type: Products | undefined = undefined,
) => {
  return useInfiniteQuery<InboxItem[]>({
    gcTime: 0,
    queryKey: [
      'inbox',
      organisation_id,
      search_term,
      lead_status,
      campaign_ids,
      campaign_type,
    ],
    queryFn: ({ pageParam }) =>
      organisation_id
        ? callApi(
            organizationFetchInboxEndpoint,
            {
              organization_id: organisation_id,
            },
            {
              search_term: search_term || null,
              lead_status: lead_status || null,
              campaign_ids: campaign_ids || null,
              campaign_type: campaign_type as string,
              latest_property_message_id: Number(pageParam),
            },
          ).then((res) => {
            return res.data;
          })
        : [],
    getNextPageParam: (lastPage) => {
      if (lastPage.length === 0) {
        return null;
      }
      return lastPage[lastPage.length - 1].latest_property_message_id;
    },
    initialPageParam: '',
  });
};

export const useSendEmail = () => {
  const utils = trpc.useUtils();
  return trpc.inbox.sendEmail.useMutation({
    onSuccess: (data, params) => {
      utils.inbox.activity.getLeadActivity.setData(
        {
          lead_id: params.lead_id,
        },
        (previous) => (previous ? [...previous, data] : [data]),
      );
    },
  });
};

export const useMarkAllMessagesAsSeen = (
  organisation_id: number,
  searchTerm: string | undefined = undefined,
  leadStatus: string[] | undefined = undefined,
  campaignIds: number[] | undefined = undefined,
  campaign_type: Products | undefined = undefined,
) => {
  const queryClient = useQueryClient();
  return trpc.inbox.markConvoAsSeen.useMutation({
    onSuccess: (data, params) => {
      queryClient.setQueriesData<LeadInbox>(
        { queryKey: ['lead_inbox', params.lead_id] },
        (previous) =>
          previous && {
            ...previous,
            messages: previous.messages.map((message) => ({
              ...message,
              attachments: message.attachments || [],
              seen: true,
            })),
          },
      );
      queryClient.setQueriesData(
        {
          queryKey: [
            'inbox',
            organisation_id,
            searchTerm,
            leadStatus,
            campaignIds,
            campaign_type,
          ],
        },
        (previous: any) => {
          const pages = previous?.pages || [];
          const updatedPages = pages.map((page: any) =>
            page.map((item: any) =>
              item.status_id !== params.lead_id
                ? item
                : { ...item, num_unseen: 0 },
            ),
          );
          return {
            pages: updatedPages,
            pageParams: previous?.pageParams,
          };
        },
      );
    },
  });
};

interface ExportToCRMParams {
  street_address: string;
  zip_code: number;
  organisation: Organisation;
}

const exportToCRM = async ({
  street_address,
  zip_code,
  organisation,
}: ExportToCRMParams) => {
  return postApi<PropertyMessage>(
    `/sendLeadWebhook?organisation_id=${organisation.id}`,
    {
      street_address,
      zip_code,
    },
  );
};

export const useExportToCRM = () => {
  return useMutation({
    mutationFn: exportToCRM,
    onSuccess: () => {
      notifications.show({
        title: 'Lead has been exported to CRM',
        message: null,
      });
    },
  });
};

interface ForwardToParams {
  street_address: string;
  zip_code: number;
  forward_to_email: string;
}

const forwardConversation = async ({
  street_address,
  zip_code,
  forward_to_email,
}: ForwardToParams) => {
  return postApi<PropertyMessage>(`/forwardConversation`, {
    street_address,
    zip_code,
    forward_to_email,
  });
};

export const useForwardConversation = () => {
  return useMutation({
    mutationFn: forwardConversation,
    onSuccess: () => {
      notifications.show({
        title: 'Email has been forwarded',
        message: null,
      });
    },
  });
};

export const useCreateNote = () => {
  const utils = trpc.useUtils();
  return trpc.inbox.createNote.useMutation({
    onSuccess: (data, params) => {
      utils.inbox.activity.getLeadActivity.setData(
        { lead_id: params.lead_id },
        (previous) => (previous ? [...previous, data] : [data]),
      );
    },
  });
};

export interface LeadInbox {
  messages: PropertyMessageWithAttachments[];
  notes: CampaignPropertyNote[];
  suggestedActions: Selectable<LeadAiSuggestions>[];
  statusChanges: Selectable<LeadStatusChanges>[];
}

export type InboxActivity = inferProcedureOutput<
  AppRouter['inbox']['activity']['getLeadActivity']
>[0];

export const useLeadInbox = (lead_id: number | undefined) =>
  trpc.inbox.activity.getLeadActivity.useQuery(
    lead_id ? { lead_id } : skipToken,
  );

export const useRecipients = (lead_id: number | undefined) =>
  trpc.inbox.getRecipients.useQuery(lead_id ? { lead_id } : skipToken);

export type InboxSuggestion = inferProcedureOutput<
  AppRouter['inbox']['getLeadSuggestions']
>[0];

export const useLeadSuggestions = (lead_id?: number) =>
  trpc.inbox.getLeadSuggestions.useQuery(lead_id ? { lead_id } : skipToken);

export const useDownloadAttachment = () => {
  return trpc.inbox.files.downloadAttachment.useMutation({
    onSuccess: (data) => {
      const a = document.createElement('a');
      a.href = `data:${data.mime_type};base64,` + data.content;
      a.download = getLabelFromUrl(data.filename) || 'attachment.pdf';
      a.click();
      notifications.show({
        title: 'Attachment downloaded',
        message: null,
      });
    },
  });
};

export const useAnalyzeInbox = (lead_id: number) =>
  useMutation({
    mutationFn: () =>
      callApi(analyzeLeadInboxEndpoint, {
        lead_id,
      }),
    onSuccess: () => {
      notifications.show({
        title: 'Inbox analysis has been scheduled.',
        message: null,
      });
    },
  });

export const useGiveAiSuggestionFeedback = (suggestion_id: number) => {
  const utils = trpc.useUtils();
  const removeSuggestions = removeSuggestionsInboxItem();

  return useMutation({
    mutationFn: (approved: boolean) =>
      callApi(
        postLeadAiFeedbackEndpoint,
        {
          suggestion_id,
        },
        {
          approved,
        },
      ).then((res) => res.data),
    onSuccess: (data) => {
      utils.inbox.getLeadSuggestions.setData(
        { lead_id: data.lead_id },
        (previous) =>
          previous
            ? previous.filter((suggestion) => suggestion.id !== suggestion_id)
            : [],
      );
      // Update inbox
      removeSuggestions(data.lead_id);
    },
  });
};
