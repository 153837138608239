import { Campaign, Products } from '../campaign/campaigns';
import { CampaignStep } from '../campaign/campaign_steps';
import { Organisation } from '../organisation/organisations';
import { faker } from '@faker-js/faker';
import {
  CampaignPropertyStatus,
  StatusOfCampaignProperty,
} from '../campaign/campaign_property_status';
import {
  Property,
  PropertyCondition,
  PropertySource,
  PropertyStatus,
  PropertyType,
} from '../property/properties.types';
import { PropertyMessage } from '../campaign/campaign_property_messages';
import { Location } from '../campaign/campaign_locations';
import { CampaignFilters, LeaseType } from '../campaign/campaign_filters';
import { OrganisationBlacklistedEmail } from '../organisation/organisation_blacklisted_emails';
import { OrganisationBlacklistedDomain } from '../organisation/organisation_blacklisted_domains';
import { CampaignOffer } from '../campaign/campaign_offers';
import { OrganisationContract } from '../organisation/organisation_contracts';
import { WorkflowStep, WorkflowStepType } from '../workflow/workflow_steps';
import {
  OrganisationCompsCriteria,
  PropertySearchSoldInLast,
} from '../organisation/organisation_comps_criteria';
import { OrganisationWorkflow } from '../workflow/organisation_workflow';
import {
  CampaignOfferRecommendation,
  CampaignOfferRecommendationWithProperty,
} from '../campaign/campaign_offer_recomendations';
import { PricingStrategy } from '../organisation/organisation_pricing_strategy';

export const fakerCampaign: Campaign = {
  id: faker.number.int(),
  price_discount: Number(faker.finance.amount()),
  organisation_id: faker.number.int(),
  name: faker.company.buzzPhrase(),
  active: faker.datatype.boolean(),
  contract_name: faker.lorem.words(2),
  contract_filename: faker.lorem.words(2),
  offers_require_approval: faker.datatype.boolean(),
  datafiniti_search: false,
  type: Products.LISTINGS,
  zip_codes: [],
  daily_offers_limit: faker.number.int(),
  workflow_id: faker.number.int(),
  contract_id: faker.number.int(),
};

export const fakerOrganisation: Organisation = {
  id: faker.number.int(),
  name: faker.company.name(),
  from_email: faker.internet.email(),
  from_name: faker.person.fullName(),
  email_signature: faker.string.alphanumeric(),
  owned_by: faker.string.uuid(),
  enable_incoming_email_parsing: faker.datatype.boolean(),
  max_campaign_offers_limit: 10,
  lead_webhook_url: faker.internet.url(),
  api_key: faker.string.uuid(),
  phone_number: null,
};

export const fakerCampaignStep: CampaignStep = {
  id: faker.string.uuid(),
  campaign_id: faker.number.int(30),
  email_template_id: faker.number.int(30),
  send_time: faker.date.future().toISOString(),
  wait_days: faker.number.int(30),
  name: faker.lorem.words(3),
  email_templates: {
    id: faker.string.uuid(),
    name: faker.lorem.words(3),
    subject_template: faker.lorem.sentence(),
    content_template: faker.lorem.paragraph(),
    attachments: [],
    organisation_id: faker.number.int(),
  },
};

export const fakerGenerateCampaignStep = (
  step: Partial<CampaignStep> = {},
): CampaignStep => ({
  id: faker.string.uuid(),
  campaign_id: faker.number.int(30),
  email_template_id: faker.number.int(30),
  send_time: faker.date.future().toISOString(),
  wait_days: faker.number.int(30),
  name: faker.lorem.words(3),
  email_templates: {
    id: faker.string.uuid(),
    name: faker.lorem.words(3),
    subject_template: faker.lorem.sentence(),
    content_template: faker.lorem.paragraph(),
    attachments: [],
    organisation_id: faker.number.int(),
  },
  ...step,
});

interface GenerateFakerPropertyMessageOptions {
  processed?: boolean;
  from_email?: string;
  from_name?: string;
}

export const generateFakerPropertyMessage = (
  options: GenerateFakerPropertyMessageOptions = {
    processed: false,
    from_email: faker.internet.email(),
    from_name: faker.person.fullName(),
  },
): PropertyMessage => {
  return {
    id: faker.number.int(),
    type: 'EMAIL',
    content: faker.lorem.paragraphs(1),
    outbound: faker.datatype.boolean(),
    campaign_step_id: faker.datatype.boolean() ? faker.number.int() : null,
    campaign_id: faker.number.int(),
    street_address: faker.location.streetAddress(),
    zip_code: Number(faker.number.int()),
    organisation_id: faker.number.int(),
    workflow_step_id: faker.number.int(),
    created_at: faker.date.recent(),
    created_by: '',
    from_email: null,
    from_name: null,
    seen: false,
    ...options,
  };
};

export const fakerPropertyMessage: PropertyMessage = {
  id: faker.number.int(),
  type: 'EMAIL',
  content: faker.lorem.paragraphs(1),
  outbound: faker.datatype.boolean(),
  campaign_step_id: faker.datatype.boolean() ? faker.number.int() : null,
  campaign_id: faker.number.int(),
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.number.int()),
  organisation_id: faker.number.int(),
  workflow_step_id: faker.number.int(),
  created_at: faker.date.recent(),
  created_by: '',
  seen: false,
};

interface generateFakerZillowPropertyDetailsParams {
  lotSize: string;
}

export const generateFakerZillowPropertyDetails = ({
  lotSize,
}: generateFakerZillowPropertyDetailsParams): any => ({
  zpid: faker.string.uuid(),
  description: faker.lorem.sentence(),
  address: {
    city: faker.location.city(),
    neighborhood: null,
    state: faker.location.state({ abbreviated: true }),
    streetAddress: faker.location.streetAddress(),
    zipcode: faker.location.zipCode(),
  },
  imgSrc: faker.image.url(),
  price: faker.number.int({ min: 100000, max: 1000000 }),
  livingAreaValue: faker.number.int(),
  livingAreaUnits: 'sqft',
  bedrooms: faker.number.int({ min: 1, max: 5 }),
  bathrooms: faker.number.int({ min: 1, max: 4 }),
  longitude: faker.location.longitude(),
  latitude: faker.location.latitude(),
  brokerageName: faker.company.name(),
  brokerId: faker.string.uuid(),
  yearBuilt: faker.number.int({ min: 1950, max: 2022 }),
  homeStatus: faker.helpers.arrayElement(['SOLD', 'PENDING', 'FOR_SALE']),
  listingProvider: {
    agentName: faker.person.firstName() + ' ' + faker.person.lastName(),
  },
  monthlyHoaFee: faker.datatype.boolean() ? faker.number.int(100) : null,
  homeType: faker.lorem.word(),
  resoFacts: {
    hasGarage: faker.datatype.boolean(),
    garageSpaces: faker.number.int({ min: 0, max: 3 }),
    lotSize: lotSize,
    atAGlanceFacts: [
      { factLabel: 'Label 1', factValue: faker.lorem.word() },
      { factLabel: 'Label 2', factValue: faker.lorem.word() },
    ],
    otherFacts: [
      { factLabel: 'Label 3', factValue: faker.lorem.word() },
      { factLabel: 'Label 4', factValue: faker.lorem.word() },
    ],
    hasWaterfrontView: faker.datatype.boolean(),
    waterfrontFeatures: faker.lorem.words(3).split(' '),
    homeType: faker.lorem.word(),
    isNewConstruction: faker.datatype.boolean(),
    daysOnZillow: faker.number.int({ min: 1, max: 100 }),
    parcelNumber: faker.string.uuid(),
  },
  attributionInfo: {
    brokerName: faker.company.name(),
    agentName: faker.person.firstName() + ' ' + faker.person.lastName(),
    mlsId: faker.string.uuid(),
    mlsName: faker.company.name() + ' MLS',
    brokerPhoneNumber: faker.phone.number(),
    agentPhoneNumber: faker.phone.number(),
  },
  nearbyHomes: [],
  priceHistory: [],
  mlsid: faker.string.uuid(),
  ownerName: faker.person.firstName() + ' ' + faker.person.lastName(),
  listed_by: {
    phone: {
      areacode: faker.phone.number(),
      prefix: faker.phone.number(),
      number: faker.phone.number(),
    },
  },
});
export const fakerZillowPropertyDetails: any = {
  zpid: faker.string.uuid(),
  description: faker.lorem.sentence(),
  address: {
    city: faker.location.city(),
    neighborhood: null,
    state: faker.location.state({ abbreviated: true }),
    streetAddress: faker.location.streetAddress(),
    zipcode: faker.location.zipCode(),
  },
  imgSrc: faker.image.url(),
  price: faker.number.int({ min: 100000, max: 1000000 }),
  livingAreaValue: faker.number.int(),
  livingAreaUnits: 'sqft',
  bedrooms: faker.number.int({ min: 1, max: 5 }),
  bathrooms: faker.number.int({ min: 1, max: 4 }),
  longitude: faker.location.longitude(),
  latitude: faker.location.latitude(),
  brokerageName: faker.company.name(),
  brokerId: faker.string.uuid(),
  yearBuilt: faker.number.int({ min: 1950, max: 2022 }),
  homeStatus: faker.helpers.arrayElement(['SOLD', 'PENDING', 'FOR_SALE']),
  listingProvider: {
    agentName: faker.person.firstName() + ' ' + faker.person.lastName(),
  },
  monthlyHoaFee: faker.datatype.boolean() ? faker.number.int(100) : null,
  homeType: faker.lorem.word(),
  resoFacts: {
    hasGarage: faker.datatype.boolean(),
    garageSpaces: faker.number.int({ min: 0, max: 3 }),
    lotSize: faker.number.int({ min: 5000, max: 20000 }) + ' sqft',
    atAGlanceFacts: [
      { factLabel: 'Label 1', factValue: faker.lorem.word() },
      { factLabel: 'Label 2', factValue: faker.lorem.word() },
    ],
    otherFacts: [
      { factLabel: 'Label 3', factValue: faker.lorem.word() },
      { factLabel: 'Label 4', factValue: faker.lorem.word() },
    ],
    hasWaterfrontView: faker.datatype.boolean(),
    waterfrontFeatures: faker.lorem.words(3).split(' '),
    homeType: faker.lorem.word(),
    isNewConstruction: faker.datatype.boolean(),
    daysOnZillow: faker.number.int({ min: 1, max: 100 }),
    parcelNumber: faker.string.uuid(),
  },
  attributionInfo: {
    brokerName: faker.company.name(),
    agentName: faker.person.firstName() + ' ' + faker.person.lastName(),
    mlsId: faker.string.uuid(),
    mlsName: faker.company.name() + ' MLS',
    brokerPhoneNumber: faker.phone.number(),
    agentPhoneNumber: faker.phone.number(),
    listingAgents: [
      {
        memberStateLicense: '522613',
        memberFullName: 'Dorsey Campbell',
        associatedAgentType: 'listAgent',
      },
    ],
  },
  nearbyHomes: [],
  priceHistory: [],
  mlsid: faker.string.uuid(),
  ownerName: faker.person.firstName() + ' ' + faker.person.lastName(),
  listed_by: {
    phone: {
      areacode: faker.phone.number(),
      prefix: faker.phone.number(),
      number: faker.phone.number(),
    },
  },
};

export const fakerCampaignPropertyStatusApproved: CampaignPropertyStatus = {
  campaign_id: faker.number.int(),
  approved: true,
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.location.zipCode()),
  status: StatusOfCampaignProperty.ACTIVE,
  id: faker.number.int(),
  created_at: new Date(),
  organisation_id: faker.number.int(),
  workflow_id: faker.number.int(),
  offer_price: faker.number.int(),
  contract_id: faker.number.int(),
};

export const fakerCampaignPropertyStatusRejected: CampaignPropertyStatus = {
  campaign_id: faker.number.int(),
  approved: false,
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.location.zipCode()),
  status: StatusOfCampaignProperty.ACTIVE,
  id: 0,
  created_at: new Date(),
  organisation_id: faker.number.int(),
  workflow_id: faker.number.int(),
  offer_price: faker.number.int(),
  contract_id: faker.number.int(),
};

export const fakerCampaignPropertyStatusCold: CampaignPropertyStatus = {
  campaign_id: faker.number.int(),
  approved: true,
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.location.zipCode()),
  status: StatusOfCampaignProperty.COLD,
  id: 0,
  created_at: new Date(),
  organisation_id: faker.number.int(),
  workflow_id: faker.number.int(),
  offer_price: faker.number.int(),
  contract_id: faker.number.int(),
};

export const fakerProperty: Property = {
  mls_id: faker.string.uuid(),
  zpid: faker.string.uuid(),
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.number.int(24947)),
  price: Number(faker.commerce.price()),
  listed_date: faker.date.past(),
  bathrooms: faker.number.int(),
  bedrooms: faker.number.int(),
  living_area_sqft: faker.number.int(),
  lot_area_sqft: faker.number.int(),
  source: PropertySource.ZILLOW,
  status: PropertyStatus.FOR_SALE,
  property_type: PropertyType.APARTMENT,
  img_src: faker.internet.url(),
  hoa_fee: faker.number.int(),
  year_built: faker.number.int(),
  parcel_number: faker.string.uuid(),
  brokerage_name: faker.company.name(),
  legal_description: faker.company.buzzPhrase(),
  broker_phone_number: faker.phone.number(),
  agent_name: faker.person.fullName(),
  agent_email: faker.internet.email(),
  agent_phone: faker.phone.number(),
  agent_company: faker.company.name(),
  agent_license: faker.string.uuid(),
  is_fsbo: faker.datatype.boolean(),
  zoning: faker.lorem.word(),
  description: faker.lorem.paragraph(),
  condition_analysis: faker.helpers.arrayElement(
    Object.values(PropertyCondition),
  ),
  condition_analysis_description: faker.lorem.paragraph(),
  zestimate: faker.number.int(),
  rent_zestimate: faker.number.int(),
};

export const generateFakeProperty = (
  partial: Partial<Property> = {},
): Property => ({
  mls_id: faker.string.uuid(),
  zpid: faker.string.uuid(),
  street_address: faker.location.streetAddress(),
  zip_code: Number(faker.location.zipCode()),
  price: Number(faker.commerce.price()),
  listed_date: faker.date.past(),
  bathrooms: faker.number.int(),
  bedrooms: faker.number.int(),
  living_area_sqft: faker.number.int(),
  lot_area_sqft: faker.number.int(),
  source: PropertySource.ZILLOW,
  status: PropertyStatus.FOR_SALE,
  property_type: PropertyType.APARTMENT,
  img_src: faker.internet.url(),
  hoa_fee: faker.number.int(),
  year_built: faker.number.int(),
  parcel_number: faker.string.uuid(),
  brokerage_name: faker.company.name(),
  legal_description: faker.company.buzzPhrase(),
  broker_phone_number: faker.phone.number(),
  agent_name: faker.person.fullName(),
  agent_email: faker.internet.email(),
  agent_phone: faker.phone.number(),
  agent_company: faker.company.name(),
  agent_license: faker.string.uuid(),
  is_fsbo: faker.datatype.boolean(),
  zoning: faker.lorem.word(),
  description: faker.lorem.paragraph(),
  condition_analysis: faker.helpers.arrayElement(
    Object.values(PropertyCondition),
  ),
  condition_analysis_description: faker.lorem.paragraph(),
  zestimate: faker.number.int(),
  rent_zestimate: faker.number.int(),
  ...partial,
});

export const fakerCampaignFilters: CampaignFilters = {
  campaign_id: faker.number.int(),
  time_on_market: faker.number.int({ min: 1, max: 300 }),
  property_types: [
    PropertyType.APARTMENT,
    PropertyType.MULTI_FAMILY,
    PropertyType.SINGLE_FAMILY,
  ],
  min_beds: faker.number.int(),
  max_beds: faker.number.int(),
  min_baths: faker.number.int(),
  max_baths: faker.number.int(),
  min_price: faker.number.int(),
  max_price: faker.number.int(),
  min_sqft: faker.number.int(),
  max_sqft: faker.number.int(),
  min_year_built: faker.number.int(),
  max_year_built: faker.number.int(),
  keywords: [faker.lorem.word(), faker.lorem.word(), faker.lorem.word()],
  community_55: faker.datatype.boolean(),
  max_hoa: faker.number.int(),
  equity_purchase: faker.datatype.boolean(),
  club_membership: faker.datatype.boolean(),
  lease: faker.helpers.arrayElement(Object.values(LeaseType)),
  min_lot_sqft: faker.number.int(),
  max_lot_sqft: faker.number.int(),
  must_have_garage: faker.datatype.boolean(),
};

export const fakerCampaignFiltersOptional: CampaignFilters = {
  campaign_id: faker.number.int(),
  time_on_market:
    faker.helpers.maybe(() => faker.number.int({ min: 1, max: 300 })) || null,
  property_types: [
    PropertyType.APARTMENT,
    PropertyType.MULTI_FAMILY,
    PropertyType.SINGLE_FAMILY,
  ],
  min_beds: faker.helpers.maybe(() => faker.number.int()) || null,
  max_beds: faker.helpers.maybe(() => faker.number.int()) || null,
  min_baths: faker.helpers.maybe(() => faker.number.int()) || null,
  max_baths: faker.helpers.maybe(() => faker.number.int()) || null,
  min_price: faker.helpers.maybe(() => faker.number.int()) || null,
  max_price: faker.helpers.maybe(() => faker.number.int()) || null,
  min_sqft: faker.helpers.maybe(() => faker.number.int()) || null,
  max_sqft: faker.helpers.maybe(() => faker.number.int()) || null,
  min_year_built: faker.helpers.maybe(() => faker.number.int()) || null,
  max_year_built: faker.helpers.maybe(() => faker.number.int()) || null,
  keywords:
    faker.helpers.maybe(() => [
      faker.lorem.word(),
      faker.lorem.word(),
      faker.lorem.word(),
    ]) || null,
  community_55: faker.helpers.maybe(() => faker.datatype.boolean()) || null,
  max_hoa: faker.helpers.maybe(() => faker.number.int()) || null,
  equity_purchase: faker.helpers.maybe(() => faker.datatype.boolean()) || null,
  club_membership: faker.helpers.maybe(() => faker.datatype.boolean()) || null,
  lease:
    faker.helpers.maybe(() =>
      faker.helpers.arrayElement(Object.values(LeaseType)),
    ) || null,
  min_lot_sqft: faker.helpers.maybe(() => faker.number.int()) || null,
  max_lot_sqft: faker.helpers.maybe(() => faker.number.int()) || null,
  must_have_garage: faker.datatype.boolean(),
};

export const fakerReplyEmail = {
  emailId: faker.string.uuid(),
  replyToId: faker.string.uuid(),
};

export const fakerOldReplyEmail = {
  body: faker.lorem.paragraphs(3),
  html: faker.lorem.paragraphs(3),
  from: faker.internet.email(),
  to: faker.internet.email(),
  subject: faker.lorem.sentence(),
  numOfAttach: 0,
  emailId: faker.string.uuid(),
  replyToId: faker.string.uuid(),
};

export const generateLocation = (
  location: Partial<Location> = {},
): Location => ({
  zip: Number(faker.location.zipCode('#####')),
  zip_code: faker.location.zipCode(),
  lat: Number(faker.location.latitude()),
  lng: Number(faker.location.longitude()),
  city: faker.location.city(),
  state_id: faker.location.state({ abbreviated: true }),
  state_name: faker.location.state(),
  zcta: faker.datatype.boolean(),
  parent_zcta: faker.string.uuid(),
  population: faker.number.int(),
  density: faker.number.int(),
  county_name: faker.location.county(),
  ...location,
});

export const generateFakerLocation = (
  location: Partial<Location> = {},
): Location => ({
  zip: Number(faker.location.zipCode('#####')),
  zip_code: faker.location.zipCode(),
  lat: Number(faker.location.latitude()),
  lng: Number(faker.location.longitude()),
  city: faker.location.city(),
  state_id: faker.location.state({ abbreviated: true }),
  state_name: faker.location.state(),
  zcta: faker.datatype.boolean(),
  parent_zcta: faker.string.uuid(),
  population: faker.number.int(),
  density: faker.number.int(),
  county_name: faker.location.county(),
  ...location,
});
export const fakerLocation: Location = {
  zip: Number(faker.location.zipCode()),
  zip_code: faker.location.zipCode(),
  lat: Number(faker.location.latitude()),
  lng: Number(faker.location.longitude()),
  city: faker.location.city(),
  state_id: faker.location.state({ abbreviated: true }),
  state_name: faker.location.state(),
  zcta: faker.datatype.boolean(),
  parent_zcta: faker.string.uuid(),
  population: faker.number.int(),
  density: faker.number.int(),
  county_name: faker.location.county(),
};

export const fakerBlacklistedEmail: OrganisationBlacklistedEmail = {
  id: faker.number.int(),
  email: faker.internet.email(),
  organisation_id: faker.number.int(),
};

export const fakerBlacklistedDomain: OrganisationBlacklistedDomain = {
  id: faker.number.int(),
  domain: faker.internet.domainName(),
  organisation_id: faker.number.int(),
};

export const fakerCampaignOffer: CampaignOffer = {
  property_zpid: faker.string.uuid(),
  campaign_id: String(faker.number.int()),
  approved: true,
  property_address: faker.location.streetAddress(),
  property_price: faker.number.int(),
};

export const fakerOrganisationContract: OrganisationContract = {
  id: faker.number.int(),
  organisation_id: faker.number.int(),
  filename: faker.lorem.words(2),
  label: faker.lorem.words(2),
  buyer_name: faker.person.fullName(),
};

export const fakerWorkflowStep: WorkflowStep = {
  id: faker.number.int(),
  name: faker.lorem.words(2),
  workflow_id: faker.number.int(),
  subject: faker.lorem.words(2),
  attachments: [],
  content: faker.lorem.paragraph(),
  wait_days: 0,
  type: WorkflowStepType.EMAIL,
};

export const fakerGenerateWorkflowStep = (
  params: Partial<WorkflowStep> = {},
): WorkflowStep => ({
  id: faker.number.int(),
  name: faker.lorem.words(2),
  workflow_id: faker.number.int(),
  subject: faker.lorem.words(2),
  attachments: [],
  content: faker.lorem.paragraph(),
  wait_days: 0,
  type: WorkflowStepType.EMAIL,
  ...params,
});

export const fakerOrganisationCompsCriteria: OrganisationCompsCriteria = {
  organisation_id: faker.number.int(),
  sqft_range: faker.number.int(),
  proximity_miles: faker.number.int(),
  sold_days: faker.helpers.arrayElement(
    Object.values(PropertySearchSoldInLast),
  ),
  year_built_range: faker.number.int(),
  lot_size_range: faker.number.int(),
  must_match_stories: faker.datatype.boolean(),
  must_match_basement: faker.datatype.boolean(),
  must_match_parking: faker.datatype.boolean(),
  must_match_school_district: faker.datatype.boolean(),
};

export const fakerContract: OrganisationContract = {
  id: faker.number.int(),
  organisation_id: faker.number.int(),
  filename: faker.lorem.words(2),
  label: faker.lorem.words(2),
  buyer_name: faker.person.fullName(),
};

export const fakerWorkflow: OrganisationWorkflow = {
  id: faker.number.int(),
  organisation_id: faker.number.int(),
  name: faker.lorem.words(2),
};

export const fakerCampaignOfferRecommendation: CampaignOfferRecommendation = {
  campaign_id: faker.number.int(),
  street_address: faker.location.streetAddress(),
  zip_code: faker.number.int(),
  score: faker.number.float(),
  score_explanation: faker.lorem.words(2),
  negative_feedback: faker.lorem.words(2),
};

export const fakerCampaignOfferRecommendationWithProperty: CampaignOfferRecommendationWithProperty =
  {
    ...fakerCampaignOfferRecommendation,
    property: fakerProperty,
  };

export const generateFakerCampaignOfferRecommendation = (
  params: Partial<CampaignOfferRecommendationWithProperty> = {},
): CampaignOfferRecommendationWithProperty => ({
  campaign_id: faker.number.int(),
  street_address: faker.location.streetAddress(),
  zip_code: faker.number.int(),
  score: faker.number.float(),
  score_explanation: faker.lorem.words(2),
  negative_feedback: faker.lorem.words(2),
  ...params,
  property: generateFakeProperty(),
});

export const fakerStrategy: PricingStrategy = {
  id: faker.number.int(),
  organisation_id: faker.number.int(),
  arv_pct_discount: faker.number.int(100),
  include_repair_costs: faker.datatype.boolean(),
  flat_discount: faker.number.int(10000),
  name: faker.lorem.words(2),
  description_target: faker.lorem.words(2),
};
