import {
  Button,
  Container,
  Flex,
  Paper,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';
import { Campaign } from 'shared/db';
import { IconTarget } from '@tabler/icons-react';
import { CampaignOnboardingStep } from './CampaignOnboardingStep';
import {
  useCampaignFilters,
  useGenerateCampaignRecommendations,
  useIsAdmin,
} from 'fe/queries';
import { modals } from '@mantine/modals';
import { CampaignFiltersView } from './CampaignFilters';
import classes from './CampaignOnboarding.module.scss';
import { CampaignForm } from 'fe/feature/layout';

interface CampaignOnboardingProps {
  campaign: Campaign;
}

export function CampaignOnboarding({ campaign }: CampaignOnboardingProps) {
  const { data: campaignFilters } = useCampaignFilters(campaign.id);
  // const { mutateAsync: generateRecommendations, isLoading: isGeneratingRecommendations } = useGenerateCampaignRecommendations()
  const { data: isAdmin } = useIsAdmin();
  const {
    mutateAsync: generateRecommendations,
    isPending: isGeneratingRecommendations,
  } = useGenerateCampaignRecommendations();

  const openFilters = () => {
    modals.open({
      title: 'Set filters',
      children: (
        <CampaignFiltersView campaign={campaign} onSuccess={modals.closeAll} />
      ),
      classNames: {
        content: classes.modal,
      },
    });
  };

  const openSettings = () => {
    modals.open({
      title: 'Set filters',
      children: (
        <ScrollArea classNames={{ root: classes.scrollAreaRoot }}>
          <CampaignForm campaign={campaign} smallMode />
        </ScrollArea>
      ),
      classNames: {
        content: classes.settingsContent,
        body: classes.settingsBody,
      },
    });
  };

  const filtersSet = Boolean(campaignFilters);
  const title = filtersSet
    ? 'Come back tomorrow for hot opportunities'
    : 'Congrats on your new campaign 🔥';
  const subheader = filtersSet
    ? 'In the meantime, feel free to review any of the below'
    : 'Complete these simple steps to get your campaign running and start sending offers';

  return (
    <Container
      size="sm"
      mt="xl"
      h="90%"
      display="flex"
      style={{ flexDirection: 'column' }}
    >
      <Container size="xs">
        <Flex direction="column" align="center" gap="sm">
          <Title order={3} fw={500}>
            {title}
          </Title>
          <Text>{subheader}</Text>
        </Flex>
      </Container>

      <Paper p="xl" mt="xl" radius="sm">
        <Flex direction="column" gap="xl">
          <CampaignOnboardingStep
            key="target"
            icon={<IconTarget />}
            title="Review campaign settings"
            description="Check the campaign name, location and type of listings."
            done={true}
            onClick={openSettings}
            buttonTxt="Review"
          />
        </Flex>
      </Paper>

      <Paper p="xl" mt="xl" radius="sm">
        <Flex direction="column" gap="xl">
          <CampaignOnboardingStep
            key="target"
            icon={<IconTarget />}
            title="Define your target properties"
            description="Tell us the condition, type and any other description regarding the properties you're looking for."
            done={Boolean(campaignFilters)}
            onClick={openFilters}
            buttonTxt="Set fitlers"
          />
        </Flex>
      </Paper>

      {isAdmin && (
        <Flex align="center" justify="center">
          <Button
            mt="xl"
            onClick={() => generateRecommendations(campaign.id)}
            loading={isGeneratingRecommendations}
          >
            (Admin only) Generate recommendations
          </Button>
        </Flex>
      )}
    </Container>
  );
}
