import { create } from 'zustand';
import { useOrganisationStore } from '../organisation/useOrganisationStore';
import { useEffect } from 'react';
import { Call, Device } from '@twilio/voice-sdk';
import { useDeviceToken } from '../inbox/useDeviceToken';
import { notifications } from '@mantine/notifications';
import { skipToken } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

type ExtraCallInfo = {
  name: string;
  startTime: Date;
};
interface MakeCallParams {
  number: string;
  name: string;
}

interface PhoneState {
  device: Device | null;
  setDevice: (item: Device) => void;
  call: Call | null;
  setCall: (item: Call | null) => void;
  extraCallInfo: ExtraCallInfo | null;
  setExtraCallInfo: (item: ExtraCallInfo | null) => void;
  callDuration: number;
  setCallDuration: (item: number) => void;
  calleeName: string;
  isMuted: boolean;
  //
  makeCall: ({ number, name }: MakeCallParams) => void;
  toggleMute: () => void;
  endCall: () => void;
  pressDigit: (digit: string) => void;
}

export const usePhoneStore = create<PhoneState>()((set, get) => {
  return {
    device: null,
    setDevice: (device) => set({ device }),
    call: null,
    setCall: (call) => set({ call }),
    extraCallInfo: null,
    setExtraCallInfo: (extraCallInfo) => set({ extraCallInfo }),
    callDuration: 0,
    setCallDuration: (callDuration) => set({ callDuration }),
    calleeName: '',
    isMuted: false,
    //
    makeCall: async ({ number, name }) => {
      const device = get().device;
      if (!device) {
        notifications.show({
          title: 'Device not initialized',
          message: 'Click the button again',
        });
        return;
      }
      const call = await device.connect({ params: { To: number } });
      set({ call, calleeName: name });
      // events
      call.on('disconnect', () => {
        console.log('Call has been disconnected');
        set({ call: null, isMuted: false });
      });
      call.on('reject', () => {
        console.log('Call has been rejected');
        set({ call: null, isMuted: false });
      });
    },
    toggleMute: () => {
      const call = get().call;
      if (call) {
        const newVal = !call.isMuted();
        call.mute(newVal);
        set({ isMuted: newVal });
      }
    },
    endCall: () => {
      const call = get().call;
      if (call) {
        call.disconnect();
        set({ call: null, isMuted: false });
      }
    },
    pressDigit: (digit) => {
      const call = get().call;
      if (call) {
        call.sendDigits(digit);
      }
    },
  };
});

export const useInitPhoneStore = () => {
  const { organisation } = useOrganisationStore();
  const { device, setDevice, endCall } = usePhoneStore(
    useShallow((s) => ({
      device: s.device,
      setDevice: s.setDevice,
      endCall: s.endCall,
    })),
  );
  const { data: token } = useDeviceToken(
    organisation?.id
      ? {
          organization_id: organisation.id,
        }
      : skipToken,
  );

  // Fetch Twilio token and initialize the device
  const initializeDevice = async () => {
    console.log('initializeDevice');
    if (!token || device) return;
    try {
      const newDevice = new Device(token.token, {
        // codecPreferences: ['opus', 'pcmu'],
        // fakeLocalDTMF: true,
        // enableRingingState: true,
      });

      newDevice.on('ready', () =>
        console.log('Device ready to make and receive calls'),
      );
      newDevice.on('error', (error) =>
        console.log(`Device error: ${error.message}`),
      );
      newDevice.on('disconnect', () => {
        console.log('Device disconnected');
        endCall();
      });

      // Listen for incoming call events
      // newDevice.on('incoming', (connection) => {
      //   setNotification('Incoming call...');
      //   setIncomingConnection(connection);

      //   connection.on('cancel', () => {
      //     setNotification('Incoming call canceled');
      //     setIncomingConnection(null);
      //   });
      // });

      setDevice(newDevice);
    } catch (error) {
      console.error('Failed to initialize device');
    }
  };

  useEffect(() => {
    initializeDevice();
  }, [token]);
};

export const isCallingEnabled = () => {
  const { device, call } = usePhoneStore((s) => ({
    device: s.device,
    call: s.call,
  }));
  return Boolean(device && !call);
};
