import { ConvoSuggestionSkeleton } from './ConvoSuggestionSkeleton';
import { Button } from '@mantine/core';
import { useGiveAiSuggestionFeedback } from 'fe/queries';

interface ConvoStatusSuggestionProps {
  suggestion_id: number;
  data: string;
  reasoning: string;
  setEmailContent: (content: string) => void;
}

export function MessageSuggestion({
  suggestion_id,
  data,
  reasoning,
  setEmailContent,
}: ConvoStatusSuggestionProps) {
  const { mutateAsync: createFeedback } =
    useGiveAiSuggestionFeedback(suggestion_id);

  return (
    <ConvoSuggestionSkeleton
      suggestion_id={suggestion_id}
      title="Send message to lead"
      description={data}
      reasoning={reasoning}
      callToActions={
        <Button
          onClick={() => {
            createFeedback(true);
            setEmailContent(data);
          }}
          color="violet"
          variant="outline"
          size="xs"
        >
          Create draft
        </Button>
      }
    />
  );
}
