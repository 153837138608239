import { Flex, Text, MultiSelect, MultiSelectProps } from '@mantine/core';
import './StyledEditor.module.scss';
import { z } from 'zod';

export const EmailAddressField = ({
  label,
  setData,
  ...props
}: MultiSelectProps & { setData: (params: any) => void }) => {
  return (
    <Flex gap="xs" align="center" style={{ flex: 1 }}>
      <Text fz="xs" fw={600}>
        {label}:
      </Text>

      <MultiSelect
        {...props}
        minLength={1}
        variant="unstyled"
        placeholder="Type here..."
        searchable
        creatable
        fz="sm"
        shouldCreate={(val) => z.string().email().safeParse(val).success}
        rightSection={<div />}
        rightSectionWidth={2}
        getCreateLabel={(query) => `${query}`}
        onCreate={(query) => {
          const item = { value: query, label: query };
          setData((current: any) => [...current, item]);
          return item;
        }}
        withinPortal
        style={{ flex: 1 }}
      />
    </Flex>
  );
};
