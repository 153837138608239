import { Flex, RingProgress } from '@mantine/core';
import { Typography } from './Typography';

interface ScoreProps {
  value: number;
  maxValue: number;
}

export function Score({ value, maxValue }: ScoreProps) {
  let scoreColor;
  const normalisedValue = (value / maxValue) * 100;

  if (normalisedValue > 70) {
    scoreColor = 'green';
  } else if (normalisedValue > 50) {
    scoreColor = 'yellow';
  } else {
    scoreColor = 'red';
  }

  return (
    <Flex gap="xs">
      <RingProgress
        size={20}
        thickness={2}
        roundCaps
        sections={[{ value: normalisedValue, color: scoreColor }]}
      />
      <Flex gap={2}>
        <Typography variant="label" fz="xs">
          {value}
        </Typography>
        <Typography c="dimmed" fz="xs">
          /
        </Typography>
        <Typography c="dimmed" fz="xs">
          {maxValue}
        </Typography>
      </Flex>
    </Flex>
  );
}
