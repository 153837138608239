import { Avatar, Badge, Flex, Text, ThemeIcon, Tooltip } from '@mantine/core';

import classes from './ConversationsListItem.module.scss';
import { InboxItem } from 'shared/db';
import { useInboxStore } from 'fe/queries';
import { formatLastMessageDate } from 'shared/formatter';
import { IconSparkles } from '@tabler/icons-react';

interface ConversationsListItemProps {
  item: InboxItem;
}

function strip(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function ConversationsListItem({ item }: ConversationsListItemProps) {
  const activeItem = useInboxStore((state) => state.activeInboxItem);
  const lastMessageContent = strip(item.latest_message_excerpt || '');
  const setActiveInboxItem = useInboxStore((state) => state.setActiveInboxItem);
  const isActive =
    item.street_address === activeItem?.street_address &&
    item.zip_code === activeItem?.zip_code;

  const numUnseen = item.num_unseen;
  const outboundLength = item.num_inbound;
  const numAll = item.num_messages;

  const finalDisplayNumber = numUnseen || numAll;
  const displayNumber = finalDisplayNumber > 9 ? '9' : finalDisplayNumber;
  return (
    <Flex
      className={`${classes.wrapper} ${isActive ? classes.active : ''}`}
      px="sm"
      py="xs"
      align="center"
      gap="sm"
      onClick={() => setActiveInboxItem(item)}
    >
      <Avatar color="blue" radius="xs" src={item.img_src} size="md" />
      <Flex
        style={{ flex: 1, overflow: 'hidden' }}
        justify="space-between"
        h="100%"
        direction="column"
        gap={0}
        wrap="wrap"
      >
        <Text truncate fw={600} fz="sm" w="100%">
          {item.street_address}
        </Text>
        <Text
          fz="sm"
          className={classes.descriptionText}
          c="dimmed"
          truncate
          style={{ lineHeight: '20px' }}
          dangerouslySetInnerHTML={{ __html: lastMessageContent }}
        />
      </Flex>
      <Flex
        w={70}
        h="100%"
        direction="column"
        align="flex-end"
        justify="space-between"
      >
        <Tooltip
          disabled={!item.has_suggestions}
          label="The Rafi AI has suggested actions for you to take"
        >
          <Badge
            color={
              item.has_suggestions
                ? 'brand'
                : numUnseen
                  ? 'blue'
                  : outboundLength
                    ? 'blue'
                    : 'gray'
            }
            variant={numUnseen ? 'filled' : 'light'}
            className={classes.badge}
            leftSection={
              item.has_suggestions && (
                <ThemeIcon size="xs" variant="transparent">
                  <IconSparkles size={10} />
                </ThemeIcon>
              )
            }
          >
            {displayNumber}
          </Badge>
        </Tooltip>

        <Text fz="xs" c="dimmed">
          {formatLastMessageDate(
            item.latest_property_message_date as unknown as string,
          )}
        </Text>
      </Flex>
    </Flex>
  );
}
