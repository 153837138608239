import { ApiEndpoint } from '../types';
import { z } from 'zod';
import { propertySchema } from 'shared/db';

const calculateCompScoreRouteSchema = z.object({
  organization_id: z.number(),
});

const calculateCompScoreParamsSchema = z.object({
  subjectProperty: propertySchema,
  comparableProperty: propertySchema,
});

export const organizationCalculateCompScoreEndpoint: ApiEndpoint<
  typeof calculateCompScoreRouteSchema,
  typeof calculateCompScoreParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/comp_score',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/comp_score`,
  method: 'POST',
  schema: calculateCompScoreParamsSchema,
};

const fetchOrgContractsRouteSchema = z.object({
  organization_id: z.number(),
});

export const organizationFetchContractsEndpoint: ApiEndpoint<
  typeof fetchOrgContractsRouteSchema
> = {
  apiUrl: '/organizations/:organization_id/contracts',
  clientUrl: ({ organization_id }) =>
    `/organizations/${organization_id}/contracts`,
  method: 'GET',
};

//
export const fetchInboxRouteSchema = z.object({
  organization_id: z.number(),
});

export const fetchInboxParamsSchema = z.object({
  search_term: z.string().nullable(),
  lead_status: z.array(z.string()).nullable(),
  campaign_ids: z.array(z.number().positive()).nullable(),
  campaign_type: z.string().nullable(),
  latest_property_message_id: z.number(),
});

export const organizationFetchInboxEndpoint: ApiEndpoint<
  typeof fetchInboxRouteSchema,
  typeof fetchInboxParamsSchema
> = {
  apiUrl: '/organizations/:organization_id/inbox',
  clientUrl: ({ organization_id }) => `/organizations/${organization_id}/inbox`,
  method: 'POST',
  schema: fetchInboxParamsSchema,
};

const createOrganizationRouteSchema = z.null();

export const createOrganizationParamsSchema = z.object({
  name: z.string(),
  from_email: z.string().email(),
  from_name: z.string(),
  owner_email: z.string().email(),
});

export const createOrganizationEndpoint: ApiEndpoint<
  typeof createOrganizationRouteSchema,
  typeof createOrganizationParamsSchema
> = {
  apiUrl: '/organizations',
  clientUrl: () => `/organizations`,
  method: 'POST',
  schema: createOrganizationParamsSchema,
};
