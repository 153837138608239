import { SupabaseClient } from '@supabase/supabase-js';
import { Location } from '../campaign/campaign_locations';
import { Property } from './properties.types';

interface CampaignPropertySearchResult {
  id: number;
  campaign_id: number;
  street_address: string;
  zip_code: number;
  search_date: Date;
}

export async function saveProperties(
  supabase: SupabaseClient,
  properties: Property[],
  ignoreDuplicates = true,
): Promise<Property[]> {
  const { data, error } = await supabase
    .from('properties')
    .upsert(properties, {
      ignoreDuplicates,
    })
    .select();

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't create properties");
  }

  console.log('Saved properties: ', properties.length);
  return data;
}

export async function updateProperty(
  supabase: SupabaseClient,
  property: Property,
) {
  const { error } = await supabase
    .from('properties')
    .update(property)
    .eq('street_address', property.street_address)
    .eq('zip_code', property.zip_code);

  if (error) {
    console.log('There was an error: ', error);
    throw new Error("Couldn't update properties");
  }

  return property;
}

interface UpdatePropertyPartiallyParams {
  street_address: string;
  zip_code: number;
  values: Partial<Property>;
}

export async function updatePropertyPartially(
  supabase: SupabaseClient,
  params: UpdatePropertyPartiallyParams,
): Promise<Property> {
  const { data, error } = await supabase
    .from('properties')
    .update(params.values)
    .eq('street_address', params.street_address)
    .eq('zip_code', params.zip_code)
    .select()
    .single();

  if (error) {
    console.log('There was an error: ', error);
    throw new Error("Couldn't update property");
  }

  return data;
}

export async function fetchCampaignProperties(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<Property[]> {
  const { data, error } = await supabase
    .from('properties')
    .select('*, leads!inner(*)')
    .eq('leads.campaign_id', campaignId);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't fetch campaign properties");
  }

  return data;
}

export async function saveCampaignProperties(
  supabase: SupabaseClient,
  campaignId: number,
  properties: Omit<CampaignPropertySearchResult, 'id' | 'search_date'>[],
) {
  const { error } = await supabase
    .from('campaign_properties_search_results')
    .delete()
    .eq('campaign_id', campaignId);

  if (error) {
    console.log('error: ', error);
    throw new Error("Couldn't delete campaign properties");
  }

  const { error: errorInserting } = await supabase
    .from('campaign_properties_search_results')
    .upsert(properties, {
      ignoreDuplicates: true,
    });

  if (errorInserting) {
    console.log('error: ', errorInserting);
    throw new Error("Couldn't insert campaign properties");
  }
}

export async function fetchSingleProperty(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<Property | null> {
  const { data, error } = await supabase
    .from('properties')
    .select('*')
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .single();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchProperty(
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
  fetchLocation = true,
): Promise<Property> {
  const select = fetchLocation ? '*, zip_codes (*)' : '*';
  const { data, error } = await supabase
    .from('properties')
    .select(select as '*')
    .eq('street_address', street_address)
    .eq('zip_code', zip_code)
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error(`Couldn't fetch property: ${street_address} ${zip_code}`);
  }

  return data;
}

export async function fetchRandomProperty(
  supabase: SupabaseClient,
): Promise<Property> {
  const { data, error } = await supabase
    .from('properties')
    .select('*')
    .limit(1)
    .single();

  if (error) {
    console.log('error: ', error);
    throw new Error(`Couldn't fetch random property`);
  }

  return data;
}

export async function fetchPropertyByAgentEmail(
  supabase: SupabaseClient,
  email: string,
): Promise<Property[]> {
  const { data, error } = await supabase
    .from('properties')
    .select('*, zip_codes (*)')
    .eq('agent_email', email);

  if (error) {
    return [];
  }

  return data;
}

export async function fetchAllPropertiesByAgentEmail(
  supabase: SupabaseClient,
  email: string,
): Promise<Property[]> {
  const { data, error } = await supabase
    .from('properties')
    .select('*, zip_codes (*)')
    .eq('agent_email', email);

  if (error) {
    return [];
  }

  return data;
}

export const fetchFormattedAddress = async (
  supabase: SupabaseClient,
  property: Property,
): Promise<string> => {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('zip', property.zip_code)
    .single<Location>();

  if (error) {
    console.log('error: ', error);
    throw new Error(`Couldn't fetch property zip code: ${property.zip_code}`);
  }

  return `${property.street_address}, ${data.city}, ${data.state_id} ${data.zip_code}`;
};

export const fetchFormattedAddressByZipcode = async (
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<string> => {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('zip', zip_code)
    .single<Location>();

  if (error) {
    console.log('error: ', error);
    throw new Error(`Couldn't fetch property zip code: ${zip_code}`);
  }

  return `${street_address}, ${data.city}, ${data.state_id} ${data.zip_code}`;
};

export async function fetchLocation(
  supabase: SupabaseClient,
  zip_code: number,
): Promise<Location> {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('zip', zip_code)
    .single<Location>();

  if (error) {
    throw new Error(`Couldn't fetch zip code: ${zip_code}`);
  }

  return data;
}

export async function fetchPropertyAddress(
  supabase: SupabaseClient,
  property: Property,
): Promise<Location> {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('zip', property.zip_code)
    .single<Location>();

  if (error) {
    throw new Error(`Couldn't fetch zip code: ${property.zip_code}`);
  }

  return data;
}

export const fetchAddressByZipcode = async (
  supabase: SupabaseClient,
  zip_code: number,
): Promise<Location> => {
  const { data, error } = await supabase
    .from('zip_codes')
    .select('*')
    .eq('zip', zip_code)
    .single<Location>();

  if (error) {
    console.log('error: ', error);
    throw new Error(`Couldn't fetch property zip code: ${zip_code}`);
  }

  return data;
};

interface PropertyLatLng {
  lat: number;
  lng: number;
}

export const fetchPropertyLatLong = async (
  supabase: SupabaseClient,
  street_address: string,
  zip_code: number,
): Promise<PropertyLatLng> => {
  const { data, error } = await supabase
    .rpc('get_property_lat_lng', {
      in_street_address: street_address,
      in_zip_code: zip_code,
    })
    .single<PropertyLatLng>();

  if (error || !data) {
    console.error('Error calling get_property_lat_lng:', error);
    throw new Error('Error fetching property lat/lng');
  }

  return data;
};
