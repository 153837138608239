import { Campaign } from 'shared/db';
import { Flex, Tabs, Loader, ThemeIcon, Tooltip } from '@mantine/core';
import { CampaignFiltersView } from '../CampaignFilters';
import classes from './CampaignSideview.module.scss';
import { CampaignForm } from 'fe/feature/layout';
import {
  ContractManagement,
  WorkflowManagement,
} from 'fe/feature/organisation-settings';
import { useCampaignFilters, useUpdateCampaign } from 'fe/queries';
import { IconAlertCircle } from '@tabler/icons-react';

interface CampaignSideviewProps {
  campaign: Campaign;
}

export function CampaignSideview({ campaign }: CampaignSideviewProps) {
  const { data: campaignFilters, isLoading: isLoadingFilters } =
    useCampaignFilters(Number(campaign.id));

  const { mutateAsync: updateCampaign, isPending: updatingCampaign } =
    useUpdateCampaign();

  const onChangeWorkflow = (newId: number) => {
    updateCampaign({ campaign_id: campaign.id, workflow_id: newId });
  };

  const onChangeContract = (newId: number | null) => {
    updateCampaign({ campaign_id: campaign.id, contract_id: newId });
  };

  return (
    <Flex direction="column" w="100%" gap="md" h="100%">
      <Tabs
        classNames={{
          root: classes.tabsRoot,
          panel: classes.tabsPanel,
        }}
        defaultValue="settings"
        keepMounted={false}
      >
        <Tabs.List grow>
          <Flex>
            <Tabs.Tab value="settings">Settings</Tabs.Tab>
            <Tabs.Tab
              value="filters"
              rightSection={
                !campaignFilters &&
                !isLoadingFilters && (
                  <Tooltip
                    label="Please set your campaign filters to be able to send offers."
                    offset={20}
                  >
                    <ThemeIcon
                      variant="light"
                      color="red"
                      radius="xl"
                      size="xs"
                      style={{ minHeight: 0, height: 0 }}
                    >
                      <IconAlertCircle />
                    </ThemeIcon>
                  </Tooltip>
                )
              }
            >
              Filters
            </Tabs.Tab>
            <Tabs.Tab
              value="workflow"
              rightSection={
                !campaign.workflow_id && (
                  <Tooltip
                    label="Please select a workflow to be able to send offers."
                    offset={20}
                  >
                    <ThemeIcon
                      variant="light"
                      color="red"
                      radius="xl"
                      size="xs"
                      style={{ minHeight: 0, height: 0 }}
                    >
                      <IconAlertCircle />
                    </ThemeIcon>
                  </Tooltip>
                )
              }
            >
              <Flex align="center" gap="xs">
                Email flow
              </Flex>
            </Tabs.Tab>
            <Tabs.Tab
              value="contract"
              rightSection={
                !campaign.contract_id && (
                  <Tooltip
                    label="Consider selecting a contract to improve response rate."
                    offset={20}
                  >
                    <ThemeIcon
                      variant="light"
                      color="yellow"
                      radius="xl"
                      size="xs"
                      style={{ minHeight: 0, height: 0 }}
                    >
                      <IconAlertCircle />
                    </ThemeIcon>
                  </Tooltip>
                )
              }
            >
              <Flex align="center" gap="xs">
                Contract
              </Flex>
            </Tabs.Tab>
          </Flex>
        </Tabs.List>

        <Tabs.Panel value="settings" pt="xs">
          <div className={classes.inboxWrapper}>
            <Flex
              p="xl"
              justify="center"
              align="center"
              direction="column"
              style={{ flex: 1, height: '100%' }}
            >
              <CampaignForm campaign={campaign} smallMode />
            </Flex>
          </div>
        </Tabs.Panel>

        <Tabs.Panel value="workflow" pt="xs">
          {campaign ? (
            <div className={classes.inboxWrapper}>
              <WorkflowManagement
                title="Select campaign workflow"
                selectedValue={campaign.workflow_id}
                onChange={onChangeWorkflow}
                isLoadingChange={updatingCampaign}
              />
            </div>
          ) : (
            <Flex w="100%" h="100%" align="center" justify="center">
              <Loader />
            </Flex>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="contract" pt="xs">
          {campaign ? (
            <div className={classes.inboxWrapper}>
              <ContractManagement
                title="Select campaign contract"
                selectedValue={campaign.contract_id}
                onChange={onChangeContract}
                isLoadingChange={updatingCampaign}
                hideFields
              />
            </div>
          ) : (
            <Flex w="100%" h="100%" align="center" justify="center">
              <Loader />
            </Flex>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="filters" pt="xs">
          {campaign ? (
            <div className={classes.inboxWrapper}>
              <CampaignFiltersView campaign={campaign as unknown as Campaign} />
            </div>
          ) : (
            <Flex w="100%" h="100%" align="center" justify="center">
              <Loader />
            </Flex>
          )}
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
}
