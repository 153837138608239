import parsePhoneNumber from 'libphonenumber-js';

export const formatPhoneNumberToE164 = (rawNumber: string) => {
  const number = parsePhoneNumber(rawNumber, 'US');
  return number?.format('E.164');
};

export const formatPhoneNumberToNational = (rawNumber: string) => {
  const number = parsePhoneNumber(rawNumber, 'US');
  return number?.formatNational();
};

export const isValidNumber = (rawNumber: string) => {
  const number = parsePhoneNumber(rawNumber, 'US');
  return number?.isValid();
};
