import { Flex, Title } from '@mantine/core';
import { OutreachInsightsCards } from './OutreachInsightsCards';
import {
  useInsightsStore,
  useOrganisationStore,
  useOutreachInsights,
} from 'fe/queries';
import { skipToken } from '@tanstack/react-query';

export function OutreachInsights() {
  const { organisation } = useOrganisationStore();
  if (!organisation) {
    return null;
  }
  const params = useInsightsStore((state) => ({
    campaignId: state.campaignId,
    dateRange: state.dateRange,
    granularity: state.granularity,
  }));
  const startDate = params.dateRange[0];
  const endDate = params.dateRange[1];

  const { data, isLoading } = useOutreachInsights(
    startDate && endDate
      ? {
          organization_id: organisation.id,
          campaign_id: params.campaignId || undefined,
          date_start: startDate.valueOf(),
          date_end: endDate.valueOf(),
          granularity: params.granularity,
        }
      : skipToken,
  );
  const quickData = data?.quickData || [];
  return (
    <Flex h={200}>
      <Flex style={{ flex: 1 }} gap="lg" direction="column">
        <Title order={2}>Outreach Insights</Title>

        <OutreachInsightsCards data={quickData} loading={isLoading} />
      </Flex>
    </Flex>
  );
}
