import { create } from 'zustand';
import {
  useRecommendations,
  useRemoveOfferRecommendation,
  useUpdateCampaignOfferRecommendation,
} from './useRecommendations';
import { useEffect } from 'react';
import { CampaignOfferRecommendationWithProperty } from 'shared/db';
import { useOrganisationStore } from './../organisation/useOrganisationStore';
import { useRejectLead } from './../campaign/useCampaignPropertyStatus';

interface RecommendationsState {
  activeOffer: CampaignOfferRecommendationWithProperty | null;
  setActiveOffer: (offer: CampaignOfferRecommendationWithProperty) => void;
  skippedOffers: CampaignOfferRecommendationWithProperty[];
  skipOffer: (offer: CampaignOfferRecommendationWithProperty) => void;
}

const useStore = create<RecommendationsState>()((set) => ({
  activeOffer: null,
  setActiveOffer: (offer: CampaignOfferRecommendationWithProperty) =>
    set({ activeOffer: offer }),
  skippedOffers: [],
  skipOffer: (offer: CampaignOfferRecommendationWithProperty) =>
    set((state) => ({
      skippedOffers: [...state.skippedOffers, offer],
    })),
}));

export const useRecommendationsStore = (campaignId: number) => {
  const storeState = useStore();
  const { organisation } = useOrganisationStore();
  const { data: recommendations, isLoading } = useRecommendations(campaignId);
  const { mutateAsync: updateOffer } = useUpdateCampaignOfferRecommendation();
  const { mutateAsync: rejectLead, isPending: isRejectingLead } =
    useRejectLead();

  const removeOffer = useRemoveOfferRecommendation(campaignId);

  useEffect(() => {
    if (!recommendations || isLoading) return;
    useStore.setState({
      activeOffer: recommendations?.[0],
    });
  }, [recommendations]);

  const currentIndex = recommendations?.findIndex(
    (offer: any) =>
      offer.street_address === storeState.activeOffer?.street_address,
  );
  const nextProperty =
    currentIndex !== -1 && currentIndex !== undefined
      ? recommendations?.[currentIndex + 1]
      : null;

  const skipOffer = () => {
    if (nextProperty && storeState.activeOffer) {
      storeState.skipOffer(storeState.activeOffer);
      storeState.setActiveOffer(nextProperty);
    }
  };

  const rejectOffer = async (negativeFeedback: string) => {
    if (!organisation) {
      return;
    }
    if (storeState.activeOffer) {
      const { property, ...activeOffer } = storeState.activeOffer;
      await updateOffer({
        ...activeOffer,
        negative_feedback: negativeFeedback,
      });
      removeOffer(storeState.activeOffer);
      storeState.skipOffer(storeState.activeOffer);
      await rejectLead({
        campaign_id: campaignId,
        street_address: storeState.activeOffer.street_address,
        zip_code: storeState.activeOffer.zip_code,
      });
    }
  };

  const approveOffer = async () => {
    if (storeState.activeOffer) {
      removeOffer(storeState.activeOffer);
      storeState.skipOffer(storeState.activeOffer);
    }
  };

  return {
    ...storeState,
    recommendations,
    isLoading,
    skipOffer,
    isSkippable: Boolean(nextProperty) && storeState.activeOffer,
    rejectOffer,
    isRejecting: isRejectingLead,
    approveOffer,
  };
};
