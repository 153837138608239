import { SupabaseClient } from '@supabase/supabase-js';
import { Location } from './campaign_locations';

export enum Products {
  LISTINGS = 'listings',
  FSBO = 'fsbo',
  EXPIRED = 'expired',
  COMMERCIAL = 'commercial',
}

export interface Campaign {
  id: number;
  price_discount: number;
  organisation_id: number;
  name: string;
  active: boolean;
  offers_require_approval: boolean;
  /**
   * @deprecated use `contract_filename` instead
   */
  contract_name: string | null;
  contract_filename: string | null;
  contract_buyer_name?: string | null;
  datafiniti_search: boolean;
  type: string | null;
  daily_offers_limit: number;
  zip_codes?: Location[];
  workflow_id?: number | null;
  contract_id?: number | null;
  smart?: boolean | null;
}

export async function fetchCampaign(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<
  Campaign & {
    zip_codes: Location[];
  }
> {
  const { data: campaign, error } = await supabase
    .from('campaigns')
    .select('*, zip_codes (*) ')
    .eq('id', campaignId)
    .limit(1)
    .single();

  if (error) {
    throw new Error(
      `Error fetching campaign with id: ${campaignId}. Error: ${error}`,
    );
  }

  return campaign;
}

export async function fetchSingleCampaign(
  supabase: SupabaseClient,
  campaignId: number,
): Promise<Campaign> {
  const { data, error } = await supabase
    .from('campaigns')
    .select()
    .eq('id', campaignId)
    .limit(1)
    .single();

  if (error) {
    throw new Error("Couldn't fetch campaign.");
  }

  return data;
}

export async function updateCampaign(
  supabase: SupabaseClient,
  updatedCampaign: Campaign,
) {
  const { error } = await supabase
    .from('campaigns')
    .update(updatedCampaign)
    .eq('id', updatedCampaign.id);

  if (error) {
    throw new Error("Couldn't update campaign");
  }

  return updatedCampaign;
}

export async function fetchCampaignWithLocation(
  supabase: SupabaseClient,
  id: number,
): Promise<Campaign> {
  const { data, error } = await supabase
    .from('campaigns')
    .select('*, usa_zip_codes (*)')
    .eq('id', id)
    .single();

  if (error) {
    throw new Error("Couldn't fetch campaigns");
  }

  return data;
}

export async function archiveCampaign(
  supabase: SupabaseClient,
  campaign: Campaign,
): Promise<Campaign> {
  const { error } = await supabase
    .from('campaigns')
    .update({
      active: false,
      deleted_at: new Date(),
    })
    .eq('id', campaign.id);

  if (error) {
    throw new Error("Couldn't archive campaign");
  }

  return campaign;
}
