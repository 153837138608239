import React, { useEffect } from 'react';
import {
  Divider,
  Flex,
  ScrollArea,
  Image,
  Title,
  Container,
  Loader,
  Skeleton,
  Text,
} from '@mantine/core';
import {
  useAddress,
  useInboxStore,
  useLeadInbox,
  useMarkAllMessagesAsSeen,
  useOrganisationStore,
} from 'fe/queries';
import { ConversationDetailSidenav } from '../ConversationDetailSidenav';
import { ConvoItem } from './ConvoItem/ConvoItem';
import { scrollToBottom } from 'shared/formatter';
import { InboxIllustration, NotFound } from 'fe/ui/shared';
import { InboxItem, Products } from 'shared/db';
import { useDisclosure } from '@mantine/hooks';
import { ConversationActions } from './ConversationActions';
import { MessageForm } from './MessageForm';

interface ConversationDetailProps {
  items: InboxItem[];
  isLoading: boolean;
}

export function ConversationDetail({
  items,
  isLoading,
}: ConversationDetailProps) {
  const { organisation } = useOrganisationStore();
  const { activeItem, markAllMessagesAsSeen } = useInboxStore((state) => ({
    activeItem: state.activeInboxItem,
    markAllMessagesAsSeen: state.markAllMessagesAsSeen,
  }));
  const {
    filterCampaignType,
    filterCampaigns,
    filterLeadStatus,
    filterSearchTerm,
  } = useInboxStore((state) => ({
    filterLeadStatus: state.filterLeadStatus,
    filterCampaigns: state.filterCampaigns,
    filterCampaignType: state.filterCampaignType,
    filterSearchTerm: state.filterSearchTerm,
  }));
  const { data: address, isLoading: isLoadingAddress } = useAddress(
    activeItem?.zip_code,
  );
  const { mutateAsync: markAsSeen } = useMarkAllMessagesAsSeen(
    organisation?.id || 0,
    filterSearchTerm,
    filterLeadStatus,
    filterCampaigns,
    filterCampaignType as Products,
  );

  const { data: activities, isLoading: isLoadingInbox } = useLeadInbox(
    activeItem?.status_id,
  );
  const [displayReply, { toggle: toggleReply, close }] = useDisclosure(false);

  useEffect(() => {
    close();
  }, [activeItem?.status_id]);

  useEffect(() => {
    if (activeItem?.status_id) {
      markAllMessagesAsSeen();
      markAsSeen({ lead_id: activeItem?.status_id });
    }
    setTimeout(() => scrollToBottom('chat-scroll'), 100);
  }, [activities, displayReply]);

  if (isLoading) {
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="md"
      >
        <Loader color="gray" />
      </Flex>
    );
  }

  if (!isLoading && items.length === 0) {
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="xl"
      >
        <Image src={NotFound} maw={400} w="80%" />

        <Container size="xs">
          <Title order={2} align="center">
            No conversations found. Send some offers to see items here.
          </Title>
        </Container>
      </Flex>
    );
  }

  if (!activeItem || !organisation)
    return (
      <Flex
        style={{ flex: 1 }}
        align="center"
        justify="center"
        direction="column"
        gap="xl"
      >
        <Image src={InboxIllustration} maw={400} w="80%" />

        <Container size="xs">
          <Title order={3} align="center">
            Click on a message to view the full conversation
          </Title>
        </Container>
      </Flex>
    );

  return (
    <Flex style={{ flex: 1 }}>
      <Flex style={{ flex: 1 }} direction="column">
        <Flex
          w="100%"
          direction="row"
          align="center"
          justify="space-between"
          py="md"
          px="xl"
          gap="md"
        >
          <Flex justify="space-between" w="100%" align="center">
            <Flex justify="flex-start" gap="sm" align="center">
              <Image
                src={activeItem.img_src}
                radius="xs"
                width={42}
                height={42}
              />
              <div>
                <Text fw={600} fz="md">
                  {activeItem.street_address}
                </Text>
                <Text c="dimmed" fz="sm">
                  {isLoadingAddress ? (
                    <Skeleton height={12} mt={8} radius="xl" />
                  ) : (
                    `${address?.city}, ${address?.state_id} ${activeItem.zip_code}`
                  )}
                </Text>
              </div>
            </Flex>
          </Flex>

          <ConversationActions item={activeItem} toggleReply={toggleReply} />
        </Flex>

        <div style={{ flex: 1, position: 'relative' }}>
          <ScrollArea
            id="chat-scroll"
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            p="xl"
            pt="0"
          >
            <Flex direction="column" style={{ flex: 1 }} gap="xl">
              {isLoadingInbox ? (
                <>
                  <Skeleton key="loader-1" height={200} mb="xs" />
                  <Skeleton key="loader-2" height={200} mb="xs" />
                </>
              ) : (
                (activities || []).map((activity, index) => (
                  <React.Fragment key={index}>
                    <ConvoItem key={`${index}-content`} item={activity} />
                  </React.Fragment>
                ))
              )}
            </Flex>

            <MessageForm
              toggleReply={toggleReply}
              displayReply={displayReply}
            />
          </ScrollArea>
        </div>
      </Flex>

      <Divider orientation="vertical" />

      <div>
        <ConversationDetailSidenav />
      </div>
    </Flex>
  );
}
