import { Campaign, Products, PropertyCondition, PropertyType } from 'shared/db';
import {
  IconAirConditioning,
  IconBuildingCommunity,
  IconCurrencyDollar,
  IconFilter,
  IconHammer,
  IconHome,
  IconRuler,
} from '@tabler/icons-react';

export const CAMPAIGN_HOME_TYPES = [
  { value: PropertyType.MULTI_FAMILY, label: 'Multi-family' },
  { value: PropertyType.APARTMENT, label: 'Apartments' },
  { value: PropertyType.SINGLE_FAMILY, label: 'Houses' },
  { value: PropertyType.MANUFACTURED, label: 'Manufactured' },
  { value: PropertyType.CONDO, label: 'Condos' },
  { value: PropertyType.LOT, label: 'LotsLand' },
  { value: PropertyType.TOWNHOUSE, label: 'Townhomes' },
];

enum LeaseType {
  Any = 'Any',
  LeaseOKWithRestrictions = 'lease_ok_with_restrictions',
  LeaseOKNoRestrictions = 'lease_ok_no_restrictions',
}

enum FilterType {
  Number = 'number',
  MultiSelect = 'multiSelect',
  Select = 'select',
  Switch = 'switch',
  Text = 'text',
  CheckboxGroup = 'checkboxGroup',
}

type Filter =
  | {
      type: FilterType.Number | FilterType.Switch;
      label: string;
      key: string;
    }
  | {
      type: FilterType.Select | FilterType.MultiSelect;
      label: string;
      key: string;
      data: { value: string; label: string | undefined }[];
    }
  | {
      type: FilterType.Text;
      label: string;
      key: string;
      placeholder: string;
    }
  | {
      type: FilterType.CheckboxGroup;
      label: string;
      description: string;
      key: string;
      options: {
        value: string;
        label?: string;
        description?: string;
      }[];
    };

interface GetFiltersResult {
  id: string;
  icon: JSX.Element;
  label: string;
  description: string;
  filters: Filter[];
}

export const getCampaignFiltersInputs = (
  campaign: Campaign,
): GetFiltersResult[] => [
  {
    id: 'general',
    icon: <IconHome />,
    label: 'General information (Required)',
    description:
      campaign.type === Products.EXPIRED
        ? 'Property types, time expired'
        : 'Property types, time on market',
    filters: [
      {
        type: FilterType.Number,
        label:
          campaign.type === Products.EXPIRED
            ? 'Time Expired'
            : 'Time On Market',
        key: 'time_on_market',
      },
      {
        type: FilterType.MultiSelect,
        label: 'Property Types',
        key: 'property_types',
        data: CAMPAIGN_HOME_TYPES,
      },
    ],
  },
  {
    id: 'prices',
    icon: <IconCurrencyDollar />,
    label: 'Prices',
    description: 'Property price, rent price',
    filters: [
      {
        type: FilterType.Number,
        label: 'Min Price',
        key: 'min_price',
      },
      {
        type: FilterType.Number,
        label: 'Max Price',
        key: 'max_price',
      },
    ],
  },
  {
    id: 'dimensions',
    icon: <IconRuler />,
    label: 'Dimensions',
    description: 'Baths, beds, square footage, lot size, build year',
    filters: [
      {
        type: FilterType.Number,
        label: 'Beds Min',
        key: 'min_beds',
      },
      {
        type: FilterType.Number,
        label: 'Beds Max',
        key: 'max_beds',
      },
      {
        type: FilterType.Number,
        label: 'Baths Min',
        key: 'min_baths',
      },
      {
        type: FilterType.Number,
        label: 'Baths Max',
        key: 'max_baths',
      },
      {
        type: FilterType.Number,
        label: 'Min SQFT',
        key: 'min_sqft',
      },
      {
        type: FilterType.Number,
        label: 'Max SQFT',
        key: 'max_sqft',
      },
      {
        type: FilterType.Number,
        label: 'Min LOT SQFT',
        key: 'min_lot_sqft',
      },
      {
        type: FilterType.Number,
        label: 'Max LOT SQFT',
        key: 'max_lot_sqft',
      },
      {
        type: FilterType.Number,
        label: 'Build Year Min',
        key: 'min_year_built',
      },
      {
        type: FilterType.Number,
        label: 'Build Year Max',
        key: 'max_year_built',
      },
    ],
  },
  {
    id: 'features',
    icon: <IconAirConditioning />,
    label: 'Property Features',
    description: 'Garage',
    filters: [
      {
        type: FilterType.Select,
        label: 'Must Have Garage',
        key: 'must_have_garage',
        data: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
    ],
  },
  {
    id: 'community',
    icon: <IconBuildingCommunity />,
    label: 'Community',
    description: 'HOA, Lease, Club membership, Equity Purchase, 55+',
    filters: [
      {
        type: FilterType.Number,
        label: 'Max HOA Fee',
        key: 'max_hoa',
      },
      {
        type: FilterType.Select,
        label: 'Lease',
        key: 'lease',
        data: [
          { value: '', label: 'Any' },
          {
            value: LeaseType.LeaseOKWithRestrictions,
            label: 'Lease OK With Restrictions',
          },
          {
            value: LeaseType.LeaseOKNoRestrictions,
            label: 'Lease OK With No Restrictions',
          },
        ],
      },
      {
        type: FilterType.Select,
        label: 'Club Membership',
        key: 'club_membership',
        data: [
          { value: '', label: 'Any' },
          { value: 'true', label: 'Club membership required' },
          { value: 'false', label: 'Club membership not required' },
        ],
      },
      {
        type: FilterType.Select,
        label: 'Equity Purchase',
        key: 'equity_purchase',
        data: [
          { value: '', label: 'Any' },
          { value: 'true', label: 'Equity purchase required' },
          { value: 'false', label: 'Equity purchase not required' },
        ],
      },
      {
        type: FilterType.Select,
        label: '55+ Community',
        key: 'community_55',
        data: [
          { value: '', label: 'Any' },
          { value: 'true', label: 'Part of a 55+ community' },
          { value: 'false', label: 'Not part of a 55+ community' },
        ],
      },
    ],
  },
  {
    id: 'keywords',
    icon: <IconFilter />,
    label: 'Keywords',
    description: 'Include certain keywords in the search',
    filters: [
      {
        type: FilterType.Text,
        label: 'Keywords',
        placeholder: 'Separate keywords with comma',
        key: 'keywords',
      },
    ],
  },
  {
    id: 'condition',
    icon: <IconHammer size="2.5rem" />,
    label: 'Property Condition',
    description:
      'Select if you have a preference on the condition of the properties you wish to contact',
    filters: [
      {
        type: FilterType.CheckboxGroup,
        label: 'Condition',
        description: 'Check all that apply',
        key: 'property_condition',
        options: [
          {
            value: PropertyCondition.Poor,
            label: PropertyCondition.Poor,
            description:
              'Properties needing major repairs or full renovation, potentially in less desirable areas.',
          },
          {
            value: PropertyCondition.Good,
            label: PropertyCondition.Good,
            description:
              'Well-maintained properties with dated features, no major issues but in need of modernization.',
          },
          {
            value: PropertyCondition.Rehabbed,
            label: PropertyCondition.Rehabbed,
            description:
              'Properties renovated within the last 5-8 years, featuring modern amenities with minimal updates needed.',
          },
          {
            value: PropertyCondition.New,
            label: PropertyCondition.New,
            description:
              'Brand new or recently constructed properties, built within the last 3 years.',
          },
        ],
      },
    ],
  },
];
