import { z } from 'zod';
import { isValidNumber } from 'shared/formatter';

export const zodPhoneNumber = z
  .string()
  .refine(
    (val) => {
      return isValidNumber(val);
    },
    {
      message: 'Invalid phone number',
    },
  )
  .optional();

export const zodPhoneNumberNullable = z
  .string()
  .optional()
  .refine(
    (val) => {
      if (!val) return true;
      return isValidNumber(val);
    },
    {
      message: 'Invalid phone number',
    },
  )
  .optional();
