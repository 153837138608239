import { isNotEmpty, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import {
  useCampaignLocations,
  useCreateCampaign,
  useOrganisationStore,
  useUpdateCampaign,
  useUpdateCampaignLocations,
} from 'fe/queries';
import { Products, Campaign } from 'shared/db';
import { useNavigate } from '@tanstack/react-router';
import { notifications } from '@mantine/notifications';

export interface CampaignFormValues {
  name: string;
  type: string | null;
  locations: string[];
  price_discount: number;
  offers_require_approval: boolean;
  active: boolean;
  daily_offers_limit: number;
  workflow_id: number | null;
  contract_id: number | null;
}

interface CampaignFormProps {
  campaign?: Campaign;
}

export function useCampaignForm({ campaign }: CampaignFormProps) {
  const { mutateAsync: create, isPending: isCreating } = useCreateCampaign();
  const { mutateAsync: update, isPending: isUpdating } = useUpdateCampaign();
  const { mutateAsync: saveLocations, isPending: savingLocations } =
    useUpdateCampaignLocations();
  const { data: campaignLocations } = useCampaignLocations(campaign?.id || 0);
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const [campaignSmart, setCampaignSmart] = useState('basic');

  const isSmart = campaignSmart === 'smart';

  const { organisation } = useOrganisationStore();

  const form = useForm<CampaignFormValues>({
    initialValues: {
      name: campaign?.name || '',
      type: campaign?.type || Products.LISTINGS,
      locations: campaignLocations?.map((zipCode) => String(zipCode.zip)) || [],
      price_discount: campaign?.price_discount || 75,
      offers_require_approval: campaign?.offers_require_approval || false,
      active: campaign?.active || true,
      daily_offers_limit: campaign?.daily_offers_limit || 10,
      workflow_id: campaign?.workflow_id || null,
      contract_id: campaign?.contract_id || null,
    },
    validate: {
      name: isNotEmpty(),
      locations: isNotEmpty(),
      price_discount: !isSmart ? isNotEmpty() : undefined,
      workflow_id: isSmart ? isNotEmpty() : undefined,
    },
  });

  const onSubmit = form.onSubmit(async (values) => {
    const { locations, ...formValues } = values;
    if (campaign) {
      await update({
        ...campaign,
        campaign_id: campaign.id,
        ...formValues,
        smart: isSmart,
      });
      await saveLocations({
        campaign,
        locations: locations.map(Number),
      });
      notifications.show({
        title: 'Campaign updated',
        message: `Campaign ${values.name} was successfully updated`,
      });
      return;
    }
    const createdCampaign = await create({
      ...formValues,
      active: true,
      organization_id: organisation?.id || 0,
      smart: isSmart,
    });
    await saveLocations({
      campaign: createdCampaign,
      locations: locations.map(Number),
    });
    navigate({
      to: `/campaigns/$campaignId`,
      params: {
        campaignId: String(createdCampaign.id),
      },
    });
  });

  const isLoading = isCreating || isUpdating || savingLocations;
  useEffect(() => {
    if (!campaignLocations || campaignLocations.length === 0) return;
    form.setValues({
      ...campaign,
      locations: campaignLocations?.map((zipCode) => String(zipCode.zip)) || [],
    });
  }, [campaign, campaignLocations]);

  const nextStep = () => {
    if (active === 1 && !form.isValid()) {
      form.validate();
      return;
    }
    if (active === 1) {
      onSubmit();
    } else {
      setActive((current) => (current < 3 ? current + 1 : current));
    }
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return {
    isLoading,
    onSubmit,
    form,
    campaignSmart,
    setCampaignSmart,
    active,
    nextStep,
    prevStep,
  };
}
