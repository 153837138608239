import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import classes from './OffersTable.module.scss';
import { useOffersTableColumns } from './OffersTableColumns';
import { useOffersTableActions } from './useOffersTableActions';
import { Campaign } from 'shared/db';

interface TableProps {
  campaign: Campaign;
  data: any[];
  isLoading: boolean;
  maxHeight?: string;
}

export const OffersTable = ({
  data,
  isLoading,
  maxHeight,
  campaign,
}: TableProps) => {
  const columns = useOffersTableColumns();

  const actionParams = useOffersTableActions(data, campaign);

  const table = useMantineReactTable({
    columns,
    data,
    ...actionParams,
    enableColumnDragging: false,
    positionToolbarAlertBanner: 'bottom',
    columnFilterDisplayMode: 'popover',
    enablePinning: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableFacetedValues: true,
    enableRowNumbers: true,
    mantineBottomToolbarProps: {
      display: 'none',
    },
    mantineTableContainerProps: {
      sx: {
        height: '100%',
        maxHeight: maxHeight ? maxHeight : undefined,
      },
    },
    enablePagination: false, //turn off pagination
    enableRowVirtualization: true, //enable row virtualization
    initialState: {
      density: 'xs',
    },
    // onColumnFiltersChange: onColumnFiltersChange, //hoist internal columnFilters state to your state
    state: {
      isLoading,
      // columnFilters
    },
    paginationDisplayMode: 'pages',
    mantinePaperProps: {
      withBorder: false,
      className: classes.tablePaperRoot,
      sx: { display: 'flex', flexDirection: 'column', height: '100%' },
    },
  });

  return <MantineReactTable table={table} />;
};
