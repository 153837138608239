import { parseFullName } from 'parse-full-name';
import { WorkflowStep } from 'shared/db';
import Handlebars from 'handlebars';

export const getDomainFromEmail = (email: string) => email.split('@').pop();

interface GetFormattedEmailParams {
  name: string;
  address: string;
  workflowStep: WorkflowStep;
  offerPrice: number;
}

export const getFormattedEmail = ({
  name,
  address,
  workflowStep,
  offerPrice,
}: GetFormattedEmailParams) => {
  const parsedAgentName = parseFullName(name || '');
  const templateVariables = {
    name,
    address,
    offerPrice: offerPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    firstName: parsedAgentName.first,
  };

  const subjectTemplate = Handlebars.compile(workflowStep.subject);
  const contentTemplate = Handlebars.compile(workflowStep.content);

  return {
    subject: subjectTemplate(templateVariables),
    content: contentTemplate(templateVariables),
  };
};

export const getOrganizationReplyToEmail = (from_email: string) => {
  return `reply@parse.${from_email.split('@').pop()}`;
};
