import { Highlight, Button } from '@mantine/core';
import { useUpdateLead } from 'fe/queries';
import { StatusOfCampaignProperty } from 'shared/db';
import { ConvoSuggestionSkeleton } from './ConvoSuggestionSkeleton';
import { formatStatusToLabel } from 'shared/formatter';

interface ConvoStatusSuggestionProps {
  suggestion_id: number;
  lead_id: number;
  suggested_status: StatusOfCampaignProperty;
  reasoning: string;
}

export function StatusSuggestion({
  suggestion_id,
  lead_id,
  suggested_status,
  reasoning,
}: ConvoStatusSuggestionProps) {
  const { mutateAsync: updateLead, isPending: updatingLead } =
    useUpdateLead(lead_id);

  const onClick = async () => {
    await updateLead({ status: suggested_status, lead_id });
  };

  const formattedStatus = formatStatusToLabel(suggested_status);
  return (
    <ConvoSuggestionSkeleton
      suggestion_id={suggestion_id}
      title={
        <Highlight
          highlight={formattedStatus || ''}
          highlightColor="violet.0"
          highlightStyles={{ padding: 4 }}
        >
          {`Change status to ${formattedStatus}`}
        </Highlight>
      }
      reasoning={reasoning}
      callToActions={
        <Button
          loading={updatingLead}
          onClick={onClick}
          color="violet"
          variant="outline"
          size="xs"
        >
          Apply
        </Button>
      }
    />
  );
}
