import { ActionIcon, FileButton, Tooltip } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons-react';
import './StyledEditor.module.scss';

export interface Props {
  setFiles: (val: File[]) => void;
}

export const UploadField = ({ setFiles }: Props) => {
  return (
    <Tooltip label="Upload attachment" withinPortal>
      <FileButton
        onChange={setFiles}
        accept="image/png,image/jpeg,application/pdf"
        multiple
      >
        {(props) => (
          <ActionIcon mr={8} color="dark" {...props}>
            <IconPaperclip size={18} />
          </ActionIcon>
        )}
      </FileButton>
    </Tooltip>
  );
};
