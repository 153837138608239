import {
  ActionIcon,
  CloseButton,
  Flex,
  Paper,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { getLabelFromUrl } from 'shared/formatter';
import { IconDownload } from '@tabler/icons-react';
import { FileIcon } from 'fe/ui/shared';
import { useDownloadAttachment } from 'fe/queries';

interface AttachmentCardProps {
  mime_type: string;
  filename: string;
  id?: number;
  remove?: () => void;
}

export function AttachmentCard({
  mime_type,
  filename,
  id,
  remove,
}: AttachmentCardProps) {
  const { mutateAsync: downloadAttachment, isPending: isDownloading } =
    useDownloadAttachment();

  return (
    <Paper
      color="transparent"
      bg="transparent"
      withBorder
      p="xs"
      w="fit-content"
    >
      <Flex align="center" gap="sm" h="100%">
        <ThemeIcon color="red" size="lg">
          <FileIcon mimeType={mime_type} />
        </ThemeIcon>

        <Flex direction="column">
          <Text size="xs" style={{ whiteSpace: 'nowrap' }}>
            {getLabelFromUrl(filename)}
          </Text>
          <Flex>
            <Text c="dimmed" size="xs" style={{ whiteSpace: 'nowrap' }}>
              {mime_type}
            </Text>
          </Flex>
        </Flex>

        {/* Specifically check for "0" because new placeholder attachments should not be doable */}
        {id && id !== 0 ? (
          <ActionIcon
            size="xl"
            onClick={() => downloadAttachment({ attachment_id: id })}
            loading={isDownloading}
          >
            <IconDownload />
          </ActionIcon>
        ) : null}

        {remove && <CloseButton onClick={remove} />}
      </Flex>
    </Paper>
  );
}
