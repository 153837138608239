import { Flex, Paper, SimpleGrid, Skeleton, Text } from '@mantine/core';
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
} from '@tabler/icons-react';
import classes from './ResponseInsightsCards.module.scss';
import { QuickData } from 'shared/api';

const icons = [
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
];

const fakeData = [
  {
    title: 'Offer sent leads',
    icon: 'coin',
    value: null,
    diff: 0.1,
  },
  {
    title: 'Responded',
    icon: 'receipt',
    value: null,
    diff: 12,
  },
  {
    title: 'Moved to CRM',
    icon: 'receipt',
    value: null,
    diff: 12,
  },
  { title: 'Warm', icon: 'receipt', value: null },
  { title: 'Cold', icon: 'user', value: null },
];

interface ResponseInsightsCardsProps {
  data: QuickData[];
  loading: boolean;
}

export function ResponseInsightsCards({
  data,
  loading,
}: ResponseInsightsCardsProps) {
  console.log('data in ResponseInsightsCards', data);
  const stats = fakeData.map((stat, i) => {
    const Icon = icons[i];
    // const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Flex justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
        </Flex>

        <Flex align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {loading || !data[i] ? (
              <Skeleton height={20} radius="xl" w={100} />
            ) : (
              data[i].value
            )}
          </Text>
          {/* <Text c={stat.diff > 0 ? 'teal' : 'red'} fz="sm" fw={500} className={classes.diff}>
            <span>{stat.diff}%</span>
            <DiffIcon size="1rem" stroke={1.5} />
          </Text> */}
        </Flex>

        {/* <Text fz="xs" c="dimmed" mt={7}>
          Compared to previous month
        </Text> */}
      </Paper>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid cols={4}>{stats}</SimpleGrid>
    </div>
  );
}
