import { Button, Flex, Text, Paper, Popover, Box } from '@mantine/core';
import { ContactForm } from './ContactForm';
import {
  formatPhoneNumberToE164,
  formatPhoneNumberToNational,
} from 'shared/formatter';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { CallButton } from './../Conversation/CallButton/CallButton';

interface ContactCardProps {
  contact_id: number;
  name: string;
  email: string;
  phone: string | null;
}

export function ContactCard({
  contact_id,
  name,
  email,
  phone,
}: ContactCardProps) {
  const [opened, { close, open }] = useDisclosure(false);

  const ref = useClickOutside(close);

  return (
    <Paper p="xs" radius="sm" w="100%" withBorder ref={ref}>
      <Flex direction="column" gap="xs">
        <Flex justify="space-between">
          <Text size="sm" fw="bold">
            Agent
          </Text>

          <Flex gap="xs">
            {phone && <CallButton name={name} number={phone} />}

            <Popover
              opened={opened}
              width={300}
              position="bottom"
              withArrow
              shadow="md"
            >
              <Popover.Target>
                <Button
                  onClick={() => {
                    if (!opened) {
                      open();
                      return;
                    }
                    close();
                  }}
                  size="xs"
                  compact
                  color="gray"
                  variant={opened ? 'default' : 'subtle'}
                >
                  {opened ? 'Close' : 'Edit'}
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <Box>
                  <ContactForm
                    id={contact_id}
                    name={name}
                    email={email}
                    phone={phone}
                    onClose={close}
                  />
                </Box>
              </Popover.Dropdown>
            </Popover>
          </Flex>
        </Flex>

        <Text size="xs">{name}</Text>
        <Text size="xs">{email}</Text>
        {phone && (
          <Text
            component="a"
            href={`tel:${formatPhoneNumberToE164(phone)}`}
            size="xs"
            color="blue"
          >
            {formatPhoneNumberToNational(phone)}
          </Text>
        )}
      </Flex>
    </Paper>
  );
}
