import { DrawerProps, Drawer as MantineDrawer } from '@mantine/core';
import classes from './Drawer.module.scss';

export function Drawer(props: DrawerProps) {
  return (
    <MantineDrawer
      {...props}
      classNames={{
        content: classes.drawer,
        header: classes.drawer,
      }}
    />
  );
}
