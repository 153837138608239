import { TextInput, Box, Button, Flex } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { z } from 'zod';
import { Organisation } from 'shared/db';
import { useCreateOrganisation, useOrganisationStore } from 'fe/queries';
import { InputWrapper } from 'fe/ui/shared';

interface OrganisationBasicFormProps {
  organisation?: Organisation;
  onSucess?: () => void;
}

export function OrganisationBasicForm({
  organisation,
  onSucess,
}: OrganisationBasicFormProps) {
  const { updateOrganisation, isUpdating: isLoadingUpdate } =
    useOrganisationStore();
  const { mutateAsync: createOrganisation, isPending: isLoadingCreate } =
    useCreateOrganisation();

  const schema = z.object({
    name: z.string().min(1),
    from_name: z.string().min(1),
    from_email: z.string().email({ message: 'Invalid email' }).min(1),
    owner_email: !organisation
      ? z.string().email({ message: 'Invalid email' }).min(1)
      : z.string().optional(),
  });

  const form = useForm({
    initialValues: {
      name: organisation?.name || '',
      from_email: organisation?.from_email || '',
      from_name: organisation?.from_name || '',
      owner_email: '',
    },
    validate: zodResolver(schema),
  });

  useEffect(() => {
    if (!organisation) return;
    form.setValues(organisation);
  }, [organisation]);

  console.log('organisation', organisation);
  const submitForm = form.onSubmit(async (values) => {
    if (organisation) {
      await updateOrganisation({
        name: values.name || '',
        from_email: values.from_email || '',
        from_name: values.from_name || '',
        email_signature: organisation?.email_signature || '',
      });
      notifications.show({
        title: 'Organization updated',
        message: `Organization, ${values.name}, was successfully updated`,
      });
    } else {
      await createOrganisation(values);
      notifications.show({
        title: 'Organisation created',
        message: `Organisation ${values.name} was successfully created. Please refresh the page to see the changes`,
      });
    }
    onSucess?.();
  });

  return (
    <Box mx="auto">
      <Flex>
        <form onSubmit={submitForm} style={{ width: '100%' }}>
          {!organisation && (
            <InputWrapper
              title="Owner's email"
              description="What's the email of the owner of this organization?"
              rightWidth={300}
            >
              <TextInput
                placeholder="your@email.com"
                {...form.getInputProps('owner_email')}
                w="100%"
              />
            </InputWrapper>
          )}
          <InputWrapper
            title="Company name"
            description="What's the name of this organization?"
            rightWidth={300}
          >
            <TextInput
              placeholder="Downtown Home Buyers"
              {...form.getInputProps('name')}
              w="100%"
            />
          </InputWrapper>

          <InputWrapper
            title="From email"
            description="What should be the email sending the offers?"
            rightWidth={300}
          >
            <TextInput
              placeholder="your@email.com"
              {...form.getInputProps('from_email')}
              w="100%"
            />
          </InputWrapper>

          <InputWrapper
            title="From Name"
            description="What's the name of the sender?"
            rightWidth={300}
          >
            <TextInput
              placeholder="your@email.com"
              {...form.getInputProps('from_name')}
              w="100%"
            />
          </InputWrapper>

          <Flex w="100%" justify="flex-end" mt="xl">
            <Button type="submit" loading={isLoadingCreate || isLoadingUpdate}>
              Save changes
            </Button>
          </Flex>
        </form>
      </Flex>
    </Box>
  );
}
