import {
  Badge,
  Button,
  Flex,
  Select,
  Text,
  Divider,
  ScrollArea,
  Box,
} from '@mantine/core';
import {
  IconBath,
  IconBed,
  IconCircleCheck,
  IconDownload,
  IconHome,
  IconLoader,
  IconStatusChange,
} from '@tabler/icons-react';
import {
  useContacts,
  useExportToCRM,
  useInboxStore,
  useLead,
  useLeadContract,
  useOrganisationStore,
  useUpdateLead,
} from 'fe/queries';
import { useEffect } from 'react';
import classes from './ConversationDetailSidenav.module.scss';
import { IconCashBanknote } from '@tabler/icons-react';
import { Products, StatusOfCampaignPropertyLabels } from 'shared/db';
import { generateOfferPrice } from 'shared/formatter';
import { notifications } from '@mantine/notifications';
import { trackLeadStatusChange } from 'fe/analytics';
import { PropertyItem } from './Sidenav/PropertyInfoItem';
import { ContactCard } from './Sidenav/ContactCard';

const CampaignTypeMapping: Record<Products, string> = {
  [Products.LISTINGS]: 'Active Listings',
  [Products.EXPIRED]: 'Expired Listings',
  [Products.COMMERCIAL]: 'Commercial Listings',
  [Products.FSBO]: 'FSBO Listings',
};

export function ConversationDetailSidenav() {
  const item = useInboxStore((state) => state.activeInboxItem);
  if (!item) return null;
  const { data: lead } = useLead(item.status_id);
  const { data: contacts } = useContacts({ lead_id: item.status_id });
  const {
    mutateAsync: updateLead,
    isPending: updatingLead,
    isSuccess: leadWasUpdated,
    reset: resetLeadUpdateQuery,
  } = useUpdateLead(item.status_id);
  const { organisation } = useOrganisationStore();
  const { mutateAsync: exportToCRM, isPending: loadingExport } =
    useExportToCRM();

  const { data: contractPDF, isLoading: isLoadingContract } = useLeadContract(
    Number(item.status_id),
  );

  let SelectIcon = <IconStatusChange size="1rem" />;
  if (updatingLead) {
    SelectIcon = <IconLoader color="blue" size="1rem" />;
  } else if (leadWasUpdated) {
    SelectIcon = <IconCircleCheck color="green" size="1rem" />;
  }

  useEffect(() => {
    if (leadWasUpdated) {
      setTimeout(() => {
        resetLeadUpdateQuery();
      }, 1000);
    }
  }, [leadWasUpdated]);

  return (
    <Flex
      direction="column"
      className={classes.wrapper}
      justify="space-between"
      h="100%"
      py="sm"
    >
      <Flex direction="column" px="sm">
        <Select
          aria-label="Select status"
          disabled={updatingLead}
          mb="md"
          size="sm"
          label="Lead status"
          placeholder="Pick a new status"
          value={lead?.status}
          icon={SelectIcon}
          onChange={async (value) => {
            if (value) {
              const oldValue = lead?.status || null;
              await updateLead({ status: value, lead_id: item.status_id });
              await trackLeadStatusChange({
                old_status: oldValue,
                new_status: value,
                street_address: item.street_address,
                zip_code: item.zip_code,
              });
              notifications.show({
                title: 'Status updated',
                message: 'The status has been updated successfully',
              });
            }
          }}
          data={StatusOfCampaignPropertyLabels}
        />
      </Flex>

      <Box style={{ position: 'relative', flex: 1 }}>
        <ScrollArea
          style={{ position: 'absolute', height: '100%', width: '100%' }}
          px="sm"
        >
          <Flex
            direction="column"
            justify="space-between"
            h="100%"
            pb="lg"
            gap="xs"
          >
            <Box>
              <Flex>
                {contacts?.map((contact) => (
                  <ContactCard
                    key={contact.id}
                    contact_id={contact.id}
                    name={contact.name}
                    email={contact.emails[0]}
                    phone={contact.phones[0] || null}
                  />
                ))}
              </Flex>
            </Box>

            <Divider my="xs" />

            <PropertyItem
              icon={<IconCashBanknote />}
              label="Listed price"
              value={item.price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              color="green"
            />
            <PropertyItem
              icon={<IconCashBanknote />}
              label="Offer price"
              value={
                item.offer_price?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }) ||
                generateOfferPrice(
                  item.price,
                  item.campaign_price_discount,
                ).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })
              }
              color="grape"
            />
            <PropertyItem
              icon={<IconHome />}
              label="Property Type"
              value={item.property_type}
              color="pink"
            />
            <Flex gap="xs">
              <PropertyItem
                icon={<IconBed />}
                label="Beds"
                value={item.bedrooms || 0}
                color="yellow"
              />
              <PropertyItem
                icon={<IconBath />}
                label="Baths"
                value={item.bathrooms || 0}
                color="orange"
              />
            </Flex>
            {/*  */}
          </Flex>
        </ScrollArea>
      </Box>

      <Flex px="sm" mt="sm" direction="column" gap="lg">
        <div>
          <Badge size="sm" variant="transparent" radius="xs" fullWidth>
            {item.campaign_type &&
              CampaignTypeMapping[item.campaign_type as Products]}
          </Badge>
          <Text fz="sm" align="center">
            {item.campaign_name}
          </Text>
        </div>

        {contractPDF || isLoadingContract ? (
          <Button
            color="gray.7"
            variant="subtle"
            loading={isLoadingContract}
            onClick={() => {
              const a = document.createElement('a');
              a.href = 'data:application/pdf;base64,' + contractPDF;
              a.download = `Contract for ${item.street_address}, ${item.zip_code}.pdf`;
              a.click();
            }}
            compact
            leftIcon={<IconDownload size="1rem" />}
          >
            Download contract
          </Button>
        ) : null}

        {organisation?.lead_webhook_url && (
          <Button
            loading={loadingExport}
            onClick={() =>
              exportToCRM({
                street_address: item.street_address,
                zip_code: item.zip_code,
                organisation,
              })
            }
            variant="outline"
            fullWidth
            size="sm"
            compact
          >
            Export to CRM
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
